var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["betting-wrap clearfix", _vm.theme] }, [
    _vm.mobile
      ? _c(
          "div",
          { staticClass: "betting-wrap-mobile" },
          [
            _c("Tabs", {
              attrs: { tabs: _vm.getTabOrder(), mobile: _vm.mobile },
              scopedSlots: _vm._u(
                [
                  {
                    key: "normal",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "betting-content-mobile" }, [
                          _c("div", { staticClass: "normal-betting" }, [
                            _c("div", { staticClass: "betting-market-wrap" }, [
                              _c(
                                "div",
                                [
                                  _c("BettingMarket", {
                                    attrs: {
                                      title: _vm.markets.field.key,
                                      bets: _vm.odds[_vm.markets.field.id],
                                      odds: _vm.markets.field.odds,
                                      select: _vm.toggleBet,
                                      disable: _vm.disabled_betting,
                                      theme: _vm.theme,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "special",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "betting-content-mobile" }, [
                          _c(
                            "div",
                            { staticClass: "special-betting" },
                            _vm._l(
                              _vm.mobileSpecialGroup,
                              function (market, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "betting-market-wrap",
                                  },
                                  [
                                    market.active
                                      ? _c(
                                          "div",
                                          [
                                            _c("BettingMarket", {
                                              attrs: {
                                                title: market.key,
                                                bets: _vm.odds[market.id],
                                                outcomes:
                                                  market.outcomes || false,
                                                odds: market.odds,
                                                select: _vm.toggleBet,
                                                disable: _vm.disabled_betting,
                                                theme: _vm.theme,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1415128915
              ),
            }),
          ],
          1
        )
      : _c("div", { staticClass: "betting-wrap-desktop" }, [
          _c("div", { staticClass: "content-spacer" }, [
            _c(
              "div",
              { staticClass: "betting-market-left" },
              _vm._l(_vm.bettingLeftGroup, function (market, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "betting-market-wrap" },
                  [
                    market.active
                      ? _c(
                          "div",
                          [
                            _c("BettingMarket", {
                              attrs: {
                                title: market.key,
                                bets: _vm.odds[market.id],
                                odds: market.odds,
                                select: _vm.toggleBet,
                                disable: _vm.disabled_betting,
                                theme: _vm.theme,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "betting-market-right" },
              _vm._l(_vm.bettingRightGroup, function (market, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "betting-market-wrap" },
                  [
                    market.active
                      ? _c(
                          "div",
                          [
                            _c("BettingMarket", {
                              attrs: {
                                title: market.key,
                                bets: _vm.odds[market.id],
                                outcomes: market.outcomes,
                                odds: market.odds,
                                select: _vm.toggleBet,
                                disable: _vm.disabled_betting,
                                theme: _vm.theme,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }