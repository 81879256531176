var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "results-group" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm._f("translate")(_vm.title)) +
          " " +
          _vm._s(_vm.formatOdds) +
          "\n  "
      ),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      _vm._l(_vm.fields, function (item, i) {
        return _c("ResultsGroupField", {
          key: i,
          attrs: {
            value: item,
            prefix: _vm.prefix,
            type: _vm.type,
            active: item.key ? i + 1 == _vm.result : item == _vm.result,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }