var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sch-wrap", class: { terminal: _vm.isTerminal } },
    [
      !_vm.isTerminal
        ? _c("Schedule", {
            ref: "schedule",
            attrs: {
              offer: _vm.schedule,
              isTerminal: _vm.isTerminal,
              roundLabel: _vm.roundLabel,
            },
            on: { selectedRound: _vm.selectRound },
          })
        : _vm._e(),
      _vm.isTerminal
        ? _c("ScheduleTerminal", {
            ref: "schedule",
            attrs: { offer: _vm.schedule, roundLabel: _vm.roundLabel },
            on: { selectedRound: _vm.selectRound },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }