var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notification-bar",
      class: [_vm.notification.type, { active: _vm.notification.active }],
    },
    [
      _c("span", { staticClass: "message" }, [
        _vm._v("\n      " + _vm._s(_vm.notification.message) + "\n    "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }