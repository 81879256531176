<template>
  <div>
    <div :class="['betting-market-label', theme]">{{title | translate}} {{parsedOdd}}</div>
    <div class="betting-market-bets">
      <Button v-for="(bet, i) in bets" :key="i"
        class="betting-button"
        :class="[ buttonType(bet) ]"
        :label="bet.outcome"
        :disabled="disable"
        @change="selectBet(bet)"
        :variation="isActive(bet) ? 'brand' : 'default'"></Button>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import { mapGetters } from 'vuex';
import { Button } from '@nsftx/games-sdk-js';

export default {
  name: 'BettingMarket',
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
    },
    bets: {
      type: [Array, Object],
    },
    odds: {
      type: Number,
    },
    disable: {
      type: Boolean,
    },
    select: {
      type: Function,
    },
    outcomes: {
      type: [Boolean, Array],
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    parsedOdd() {
      return `(${this.odds}.00)`;
    },
    ...mapGetters('gamesBetslip', [
      'ticket',
    ]),
  },
  methods: {
    selectBet(bet) {
      if (!this.disabled) {
        this.select(bet);
      }
    },
    isActive(bet) {
      return find(this.ticket, betOnTicket => betOnTicket.id === bet.id);
    },
    buttonType(bet) {
      const mapBetType = {
        1: 'button-normal-8',
        2: 'button-special-2',
        3: 'button-special-2',
        4: 'button-normal-3',
        5: 'button-normal-8',
        6: 'button-special-2',
        7: 'button-special-3',
      };
      return mapBetType[bet.type];
    },
  },
};
</script>

<style scoped lang="scss">
.brand {
  color: var(--custom-text-color);
}
.betting-market-label {
  text-align: center;
  color: var(--text-primary-2);
  padding-bottom: 8px;
  font-weight: 500;
}
.betting-button {
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 2px;
  border: 1px solid var(--card);
}
.button-special-3 {
  display: inline-block;
  width: 32%;
  height: 40px;
  padding: 1px;
  margin-right: 2%;
}

.button-special-2 {
  display: inline-block;
  width: 49%;
  height: 40px;
  padding: 1px;
  margin-right: 2%;
}

.button-normal-3 {
  width: 32%;
  margin-right: 2%;
  height: 40px;
  padding: 1px;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }
}

.button-special-2:last-child,
.button-special-3:last-child {
  margin-right: 0;
}

.button-normal-8 {
  width: 46px;
  height: 40px;
  padding: 1px;
  display: inline-block;
}

@media (min-width: $break-medium-extra) {
  .button-normal-8 {
    width: 46px;
  }
}

@media(max-width: $break-small) {
  .button-normal-8 {
    width: 12.5%;
  }

}
</style>
