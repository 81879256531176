var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "main-container" }, [
        _c("div", { staticClass: "game-content" }, [
          _c("div", { staticClass: "main-wrapper" }, [
            _c(
              "div",
              { staticClass: "main" },
              [
                _vm.showModal
                  ? _c("Modal", {
                      ref: "modal",
                      staticClass: "events-container",
                      attrs: {
                        fullScreen: _vm.isMob,
                        showFooter: true,
                        width: "100%",
                        height: "100%",
                      },
                      on: {
                        closeModal: _vm.closeDetails,
                        closeModalOutside: function ($event) {
                          return _vm.closeDetails("outside")
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "product-info" }, [
                                    _vm._v(_vm._s(_vm.productName)),
                                  ]),
                                  _c("div", { staticClass: "round-info" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.roundLabel) +
                                          "\n                "
                                      ),
                                    ]),
                                    _vm._v(_vm._s(_vm.currentScheduleRound)),
                                  ]),
                                  _c("i", {
                                    staticClass: "icon icon-close-a",
                                    on: { click: _vm.closeDetails },
                                  }),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c(
                                  "Tabs",
                                  _vm._b(
                                    {
                                      attrs: {
                                        tabs: _vm.tabComponents,
                                        activeTab: _vm.activeTab,
                                      },
                                      on: { change: _vm.updateTab },
                                    },
                                    "Tabs",
                                    _vm.$attrs,
                                    false
                                  )
                                ),
                                _c(
                                  _vm.component.component,
                                  _vm._b(
                                    {
                                      tag: "component",
                                      attrs: {
                                        config_bets: _vm.product_config.bets,
                                        signal: _vm.round_phase == "results",
                                        mobile: _vm.mobile,
                                      },
                                    },
                                    "component",
                                    _vm.$attrs,
                                    false
                                  )
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3037017973
                      ),
                    })
                  : _vm._e(),
                _c("div", { staticClass: "top-bar" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.productNameLabel) +
                      "\n          "
                  ),
                  _vm.showCountdown
                    ? _c("span", { staticClass: "next-round" }, [
                        _vm._v(_vm._s(_vm.nextRound) + " "),
                      ])
                    : _vm._e(),
                  _vm.showCountdown
                    ? _c("span", { staticClass: "countdown" }, [
                        _vm._v(_vm._s(_vm.minutes) + ":" + _vm._s(_vm.seconds)),
                      ])
                    : _vm._e(),
                  _vm.showRoundInProgress
                    ? _c("span", { staticClass: "next-round" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.inProgressLabel) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _c("i", {
                    staticClass: "icon icon-info info-button",
                    on: { click: _vm.toggleModal },
                  }),
                ]),
                _c("NotificationBar"),
                _c(
                  "div",
                  { staticClass: "schedule-wrapper" },
                  [
                    _c("ScheduleWrapper"),
                    _c("FloatBetslipButton", { staticClass: "float-button" }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "spacer-v" },
                  [_c("Betting", { attrs: { mobile: _vm.mobile } })],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "betslip-wrapper",
              class: { show: _vm.showBetslip },
            },
            [
              _c("BetslipArea", {
                attrs: { betslipLockedMessage: _vm.betslipMessage },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }