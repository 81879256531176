<template>
  <div class="statistics-group-progress">
    <div class="title">{{ title | translate }}</div>

    <div class="content">
      <div :class="{terminal: isTerminal}" class="label-left-wrap">
        <div class="label" v-for="(item, i) in stats"
                           :key="i">
          <span v-if="isPrefixed">
            {{ fields | translate | firstLetter }}{{ item.label }}
          </span>
          <span v-else>
            {{ fields[i].key | translate }}
          </span>
        </div>

      </div>

      <div class="progress-middle-wrap" :class="{terminal: isTerminal}">
        <div v-for="(item, i) in stats"
             :key="i">
          <StatisticsGroupProgressBar :value="item.value"
                                      :type="type"
                                      :num="i"/>
        </div>
      </div>

      <div class="value-right-wrap" :class="{terminal: isTerminal}">
        <div class="value" v-for="(item, i) in stats"
                           :key="i">
          {{ parseInt(item.value) }}%
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import StatisticsGroupProgressBar from '@/components/statistics/StatisticsGroupProgressBar';

export default {
  name: 'StatisticsGroupProgress',

  components: {
    StatisticsGroupProgressBar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    stats: {
      type: Array,
    },
    fields: {
      type: [String, Array, Object],
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters([
      'isTerminal',
    ]),
    isPrefixed() {
      return (typeof this.fields) === 'string';
    },
  },
};
</script>
<style lang="scss" scoped>
.statistics-group-progress {

  .title {
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 10px;
    color: var(--text-primary-1);
  }
  .content {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between!important;
  }

  .label-left-wrap {
    flex: 0 0 8%;
    max-width: 30%;

    .label {
      height: 15px;
      line-height: 1;
      margin-bottom: 10px;
      margin-right: 5px;
      text-transform: uppercase;
      color: var(--text-primary-1);
    }
  }

  .progress-middle-wrap {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    flex-grow: 2;
  }

  .value-right-wrap {
    text-align: right;
    flex: 0 0 10%;
    max-width: 10%;

    .value {
      font-size: 14px;
      line-height: 15px;
      margin-bottom: 10px;
      color: #acacad;
    }
  }
}
@media (min-width: 1910px) and (max-width: 1925px) {
  .statistics-group-progress {
    //  height: 20px;
    .content {
      .label-left-wrap.terminal {
        .label {
          margin-bottom: 4.5px;
        }
      }
      .value-right-wrap.terminal {
        .value {
          margin-bottom: 4.5px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
