var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "betslip-area", class: { tablet: _vm.isTablet } },
    [
      _c(_vm.betslipComponent, {
        tag: "component",
        attrs: { bgImage: _vm.showBetslipBgImage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }