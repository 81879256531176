<template>
  <div :class="['statistics clearfix', theme]">

    <div class="mobile" v-if="mobile">
      <div>
        <Dropdown :items="arrayItems"
                  :value="'key'"
                  :translate="true"
                  :limit="8"
                  @dropdownSelect="select"/>
      </div>
      <div class="selected">
        <div class="wrap-top" v-if="selected.type == 'field'">
          <div class="field-group">
            <StatisticsGroupField :title="markets.field.hot.key"
                                  :stats="statistics.field.hot"/>
          </div>
          <div class="field-group right">
            <StatisticsGroupField :title="markets.field.cold.key"
                                  :stats="statistics.field.cold"/>
          </div>
        </div>
        <div class="wrap-main" v-else>
          <div class="field-group-progress">
            <StatisticsGroupProgress :title="selected.key"
                                    :type="selected.type"
                                    :fields="selected.prefix || selected.outcomes"
                                    :stats="statistics[selected.type]">
            </StatisticsGroupProgress>
          </div>
        </div>
      </div>
    </div>

    <div class="desktop" v-else>
      <div class="wrap-top">
        <div class="field-group">
          <StatisticsGroupField :title="markets.field.hot.key"
                                :stats="statistics.field.hot"/>
        </div>
        <div class="field-group right">
          <StatisticsGroupField :title="markets.field.cold.key"
                                :stats="statistics.field.cold"/>
        </div>
      </div>

      <div class="wrap-main">
        <div class="wrap-left">
          <div v-for="(item, i) in statsLeftGroup"
               :key="i">
             <template v-if="item.active">
              <div class="field-group-progress">
                <StatisticsGroupProgress
                  :title="item.key"
                  :type="item.type"
                  :fields="item.prefix || item.outcomes"
                  :stats="statistics[i]">
                </StatisticsGroupProgress>
              </div>
             </template>
          </div>
        </div>

        <div class="wrap-right">
          <div v-for="(item, i) in statsRightGroup"
               :key="i">
            <template v-if="item.active">
              <div class="field-group-progress">
                <StatisticsGroupProgress
                  :title="item.key"
                  :type="item.type"
                  :fields="item.prefix || item.outcomes"
                  :stats="statistics[i]">
                </StatisticsGroupProgress>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import StatisticsGroupProgress from '@/components/statistics/StatisticsGroupProgress';
import StatisticsGroupField from '@/components/statistics/StatisticsGroupField';
import Dropdown from '@/components/common/Dropdown';

export default {
  name: 'Statistics',

  components: {
    StatisticsGroupProgress,
    StatisticsGroupField,
    Dropdown,
  },
  props: {
    mobile: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      markets: {
        field: {
          hot: {
            key: 'general.hot_fields',
            value: 'hot',
          },
          cold: {
            key: 'general.cold_fields',
            value: 'cold',
          },
          type: 'field',
          key: 'general.field',
        },
        column: {
          key: 'general.column',
          prefix: 'general.column',
          type: 'column',
        },
        row: {
          key: 'general.row',
          prefix: 'general.row',
          type: 'row',
        },
        odd_even: {
          key: 'betting.odd_or_even',
          type: 'odd_even',
          outcomes: [
            {
              key: 'general.odd',
              value: 'odd',
            },
            {
              key: 'general.even',
              value: 'even',
            },
          ],
        },
        color: {
          key: 'betting.purple_or_yellow',
          type: 'color',
          outcomes: [
            {
              key: 'general.purple',
              value: 'purple',
            },
            {
              key: 'general.yellow',
              value: 'yellow',
            },
          ],
        },
        side: {
          key: 'general.side',
          type: 'side',
          outcomes: [
            {
              key: 'general.left',
              value: 'left',
            },
            {
              key: 'general.right',
              value: 'right',
            },
          ],
        },
        no_goal: {
          key: 'betting.no_goal',
          type: 'no_goal',
          outcomes: [
            {
              key: 'general.miss',
              value: 'miss',
            },
            {
              key: 'general.save',
              value: 'save',
            },
            {
              key: 'general.post',
              value: 'post',
            },
          ],
        },
      },
      selected: null,
    };
  },
  created() {
    this.data = this.statistics;
    this.selected = this.markets.field;
    this.setBetsConfig();
  },
  methods: {
    setBetsConfig() {
      _.forEach(this.product_config.bets, (bet) => {
        const market = this.markets[bet.keyname];
        market.id = bet.id;
        market.active = bet.active;
        market.odds = bet.odds;
      });
    },
    select(item) {
      this.selected = item;
    },
  },
  computed: {
    ...mapGetters([
      'statistics',
      'product_config',
      'theme',
    ]),
    arrayItems() {
      return Object.keys(this.markets).map(market => this.markets[market]);
    },
    statsLeftGroup() {
      return {
        column: this.markets.column,
        color: this.markets.color,
        side: this.markets.side,
      };
    },
    statsRightGroup() {
      return {
        row: this.markets.row,
        odd_even: this.markets.odd_even,
        no_goal: this.markets.no_goal,
      };
    },
  },
};
</script>

<style scoped lang="scss">

.statistics {
  background: var(--card);
  padding-top: 15px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 30px;
  width: 100%;

  .wrap-top {
    width: 100%;
    text-align: center;
  }

  .wrap-left {
    width: 100%;
  }

  .wrap-right {
    width: 100%;
  }
  .field-group {
    display: inline-block;
    width: 100%;

    &:first-child {
      margin-bottom: 10px;
    }
  }

  .field-group-progress {
    margin-top: 10px;
  }

  @media (min-width: 660px) and (max-width: 1268px) {
    .wrap-top {
      text-align: unset;
    }

    .wrap-left {
      display: inline-block;
      vertical-align: top;
      width: 45%;
    }
    .wrap-right {
      float: right;
      clear: both;
      vertical-align: top;
      width: 45%;
    }

    .wrap-main {
      width: 100%;
      padding: 10px;
    }

    .field-group {
      display: inline-block;
      width: 45%;
      vertical-align: top;
      text-align: right;

      &.right {
        float: right;
        clear: both;
        text-align: left;
      }
    }
  }

  .mobile {
    padding-top: 15px;
    ::v-deep .dropdown {
      line-height: 40px;
      .list {
        height: 170px;
        overflow: scroll;
      }
    }
  }

  .selected {
    padding: 30px 10px;
  }
}

  .statistics-wrap-mobile {
    padding: 20px 0;
  }

  .statistics-selected {
    padding-top: 40px;
  }

  .statistics-fields-wrap {
    width: 100%;
    text-align: center;
  }

  .statistics-fields-hot,
  .statistics-fields-cold {
    display: inline-block;
    padding-bottom: 10px;

    .title {
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 9px;
      font-weight: 500;
    }
  }
  .statistics-fields-hot {
    padding-right: 90px;
  }
  .field-wrap {
    display: inline-block;
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }
  }
  .field-button {
    width: 38px;
    height: 38px;
    border-radius: 2px;
    background-color: var(--primary);
    text-align: center;

    span {
      line-height: 38px;
    }
  }

  .field-label {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #808080;
    padding-top: 3px;
  }

  .statistics-fields-wrap-left,
  .statistics-fields-wrap-right {
    display: inline-block;
    vertical-align: top;
    width: 45%;
  }

  .statistics-fields-wrap-right {
    float: right;
    clear: both;
  }

  .statistics-fields-wrap-left div,
  .statistics-fields-wrap-right div{
    margin-bottom: 10px;
  }

  @media(max-width: $break-small) {
    .statistics-fields-hot {
      display: block;
      padding-right: 0;
      text-align: center;
    }
    .statistics-fields-cold {
      display: block;
      text-align: center;
    }
    .statistics-fields-wrap-left,
    .statistics-fields-wrap-right {
      width: 100%;
    }
    .fields-wrap {
      display: inline-block;
    }
  }


  @media (min-width: $break-medium) {
    .statistics-fields-wrap-left,
    .statistics-fields-wrap-right {
      width: 100%;
    }

    .statistics-fields-wrap-right {
      float: none;
    }

    .statistics-fields-hot {
      padding-right: 0;
    }
  }
  ::v-deep .wrap-top {
    .right {
      .statistics-group-field {
        .content {
          .field-wrap {
            .field {
              background-color: var(--primary);
            }
          }
        }
      }
    }
  }
</style>
