<template>
  <div class="notification-bar" :class="[notification.type, {active: notification.active} ]">
      <span class="message">
        {{notification.message}}
      </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'NotificationBar',


  data() {
    return {
    };
  },
  methods: {
    ...mapActions([
      'deactivate_notification',
    ]),
  },
  computed: {
    ...mapGetters([
      'notification',
      'notification_active',
    ]),
  },
  watch: {
    notification_active(valueNew) {
      if (valueNew) {
        setTimeout(() => {
          this.deactivate_notification();
        }, 3000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .notification-bar {
    display: none;
    width: 100%;
    height: 35px;
    text-align: center;
    margin: 5px 0;
    font-weight: 500;

    &.active {
      display: block;
    }

    > .message {
      vertical-align: middle;
      line-height: 35px;
    }

    &.error {
      background: #e53935;
    }

    &.success {
      background: #388e3c;
    }

    &.info {
      background: #ffc107;
      color: #1f2123;
    }

  }

</style>
