<template>
  <div :class="['betting-wrap clearfix', theme]">
    <div class="betting-wrap-mobile" v-if="mobile">
      <Tabs :tabs="getTabOrder()"
            :mobile="mobile">
        <template v-slot:normal>
          <div class="betting-content-mobile">
            <div class="normal-betting">
              <div class="betting-market-wrap">
                <div>
                  <BettingMarket :title="markets.field.key"
                                :bets="odds[markets.field.id]"
                                :odds="markets.field.odds"
                                :select="toggleBet"
                                :disable="disabled_betting"
                                :theme="theme">
                  </BettingMarket>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:special>
            <div class="betting-content-mobile">
              <div class="special-betting">
                <div class="betting-market-wrap" v-for="(market, i) in mobileSpecialGroup"
                                                 :key="i">
                    <div v-if="market.active">
                      <BettingMarket :title="market.key"
                                    :bets="odds[market.id]"
                                    :outcomes="market.outcomes || false"
                                    :odds="market.odds"
                                    :select="toggleBet"
                                    :disable="disabled_betting"
                                    :theme="theme">
                      </BettingMarket>
                    </div>
                </div>
              </div>
            </div>
        </template>
      </Tabs>
    </div>
    <div class="betting-wrap-desktop" v-else>
      <div class="content-spacer">
        <!-- LEFT -->
        <div class="betting-market-left">
            <div class="betting-market-wrap" v-for="(market, i) in bettingLeftGroup"
                                            :key="i">

                <div v-if="market.active">
                  <BettingMarket :title="market.key"
                                :bets="odds[market.id]"
                                :odds="market.odds"
                                :select="toggleBet"
                                :disable="disabled_betting"
                                :theme="theme">
                  </BettingMarket>
                </div>

            </div>
        </div>
        <!-- RIGHT -->
        <div class="betting-market-right">
          <div class="betting-market-wrap" v-for="(market, i) in bettingRightGroup"
                                           :key="i">
              <div v-if="market.active">
                <BettingMarket :title="market.key"
                              :bets="odds[market.id]"
                              :outcomes="market.outcomes"
                              :odds="market.odds"
                              :select="toggleBet"
                              :disable="disabled_betting"
                              :theme="theme">
                </BettingMarket>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forEach, find } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import BettingMarket from '@/components/betting/BettingMarket';
import Tabs from '@/components/common/tabs/Tabs';

export default {
  name: 'Betting',
  components: {
    BettingMarket,
    Tabs,
  },
  props: {
    send: {
      type: Function,
    },
    remove: {
      type: Function,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      markets: {
        field: {
          key: 'betting.field_betting',
          bets: [],
        },
        column: {
          key: 'betting.column_betting',
          bets: [],
        },
        row: {
          key: 'betting.row_betting',
          bets: [],
        },
        odd_even: {
          key: 'betting.odd_or_even',
          bets: [],
          outcomes: [
            {
              value: 'odd',
              key: 'general.odd',
            },
            {
              value: 'even',
              key: 'general.even',
            },
          ],
        },
        color: {
          key: 'betting.purple_or_yellow',
          bets: [],
          outcomes: [
            {
              value: 'purple',
              key: 'general.purple',
            },
            {
              value: 'yellow',
              key: 'general.yellow',
            },
          ],
        },
        side: {
          key: 'betting.left_or_right',
          bets: [],
          outcomes: [
            {
              value: 'left',
              key: 'general.left',
            },
            {
              value: 'right',
              key: 'general.right',
            },
          ],
        },
        no_goal: {
          key: 'betting.no_goal',
          bets: [],
          outcomes: [
            {
              value: 'miss',
              key: 'general.miss',
            },
            {
              value: 'save',
              key: 'general.save',
            },
            {
              value: 'post',
              key: 'general.post',
            },
          ],
        },
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions([
      'activate_bet',
      'deactivate_bet',
      'activate_notification',
      'gamesBetslip/removeBet',
      'gamesBetslip/setIsPayinInProgress',
      'gamesBetslip/addBet',
    ]),
    init() {
      forEach(this.product_config.bets, (bet) => {
        const market = this.markets[bet.keyname];
        market.id = bet.id;
        market.active = bet.active;
        market.odds = bet.odds;
      });
    },
    getTabOrder() {
      const tabs = {
        0: { name: 'normal', key: 'general.normal_betting' },
        1: { name: 'special', key: 'general.special_betting' },
      };
      if ('bettingTabsOrder' in this.config.ui.config) {
        return [tabs[this.config.ui.config.bettingTabsOrder[0]],
          tabs[this.config.ui.config.bettingTabsOrder[1]]];
      }
      return [tabs[0], tabs[1]];
    },
    toggleBet(bet) {
      const selectedBet = find(this.ticket, betOnTicket => betOnTicket.id === bet.id);
      if (selectedBet) {
        this.removeBet(selectedBet);
        selectedBet.active = false;
      } else {
        this.addBet(bet);
      }
    },
    addBet(bet) {
      const outcome = String(bet.outcome);
      const odd = Number(`${(bet.odd)}.00`);
      // eslint-disable-next-line
      bet.outcome = outcome;
      // eslint-disable-next-line
      bet.odds = Number(odd);
      // eslint-disable-next-line
      bet.roundNumber = bet.round;
      // reset bet stake
      // eslint-disable-next-line
      bet.stake = 0;
      this['gamesBetslip/addBet'](bet);
      this['gamesBetslip/setIsPayinInProgress'](false);
      this.activate_bet(bet);
    },
    removeBet(bet) {
      this['gamesBetslip/removeBet'](bet.id);
      this.deactivate_bet(bet);
    },
    validateBetNumberLimit() {
      const keys = Object.keys(this.active_bets).length;
      return keys < this.product_config.rules.maxBetNumber.value;
    },
  },

  computed: {
    ...mapGetters('gamesBetslip', [
      'ticket',
    ]),
    ...mapGetters([
      'odds',
      'product_config',
      'round',
      'disabled_betting',
      'active_bets',
      'theme',
      'config',
    ]),
    bettingLeftGroup() {
      return {
        field: this.markets.field,
        column: this.markets.column,
        row: this.markets.row,
      };
    },
    bettingRightGroup() {
      return {
        odd_even: this.markets.odd_even,
        color: this.markets.color,
        side: this.markets.side,
        no_goal: this.markets.no_goal,
      };
    },
    mobileSpecialGroup() {
      return {
        column: this.markets.column,
        row: this.markets.row,
        odd_even: this.markets.odd_even,
        color: this.markets.color,
        side: this.markets.side,
        no_goal: this.markets.no_goal,
      };
    },
  },
};
</script>

<style scoped lang="scss">

.betting-wrap {
  position: relative;
  background: var(--card);
  clear: both;
  z-index: 0;
}

.clearfix::before,
.clearfix::after{
  content: " ";
  display: table;
}

.betting-content-mobile {
  padding: 20px 10px;

  > .normal-betting {
    width: 100%;
    margin: 0 auto;
  }

  > .special-betting {
    width: 100%;
    margin: 0 auto;
  }
}

.content-spacer {
  padding: 16px 8px;
}

.betting-market-left {
  float: left;
  width: 395px;
}

.betting-market-right {
  padding-left: 395px;
}

.betting-market-wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;

  > div {
    margin-bottom: 16px;
  }
}
.betting-market-disabled-overlay {
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.3);
}

@media (min-width: 660px) {
  .betting-market-left {
    width: 370px;
  }

  .betting-market-right {
    padding-left: 386px;
  }
}
</style>
