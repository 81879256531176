var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "statistics-group-progressbar",
        _vm.theme,
        { terminal: _vm.isTerminal },
      ],
    },
    [
      _c("div", {
        staticClass: "fill",
        class: { [_vm.parseClass + _vm.num]: true },
        style: { width: _vm.value + "%" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }