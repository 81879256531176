<template>
  <div class="home">
    <component :is="appLayout"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Expanded from '@/layouts/Expanded';
import Compact from '@/layouts/Compact';
import Terminal from '@/layouts/Terminal';
import TerminalResults from '@/layouts/TerminalResults';
import TerminalHistory from '@/layouts/TerminalHistory';
import TerminalTicketDetails from '@/layouts/TerminalTicketDetails';

export default {
  name: 'home',
  components: {
    Expanded,
    Compact,
    Terminal,
    TerminalResults,
    TerminalHistory,
    TerminalTicketDetails,
  },
  computed: {
    ...mapGetters([
      'config',
      'appLayout',
    ]),
    appLayout() {
      return this.config.appLayout;
    },
  },
};
</script>
