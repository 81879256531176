var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "results-group-field",
        _vm.theme,
        _vm.type,
        { active: _vm.active },
      ],
    },
    [
      _vm.checkFieldType
        ? _c("span", [
            _vm._v(
              "\n    " + _vm._s(_vm._f("translate")(_vm.value.key)) + "\n  "
            ),
          ])
        : _vm.prefix
        ? _c("span", [
            _vm._v(
              "\n    " +
                _vm._s(_vm._f("firstLetter")(_vm._f("translate")(_vm.prefix))) +
                _vm._s(_vm.value) +
                "\n  "
            ),
          ])
        : _c("span", [_vm._v("\n    " + _vm._s(_vm.value) + "\n  ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }