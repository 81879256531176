<template>
  <div class="main-container" v-if="loaded">
    <div class="content">
      <Scrollable>
        <Modal :fullScreen="isMob"
          :showFooter="true"
          class="events-container"
          width="100%"
          height="100%"
          v-if="showModal"
          @closeModal="closeDetails"
          @closeModalOutside="closeDetails('outside')"
          ref="modal">
          <template #header>
            <div class="info">
              <div class="product-info">{{ productName }}</div>
              <div class="round-info"><span>{{ roundLabel }}
                </span>{{ currentScheduleRound }}</div>
              <i class="icon icon-close-a"
                @click="closeDetails" />
            </div>
          </template>
          <template #body>
            <Tabs :tabs="tabComponents"
              :activeTab="activeTab"
              v-bind="$attrs"
              @change="updateTab"></Tabs>
            <component v-bind:is="component.component"
                v-bind="$attrs"
                :config_bets="product_config.bets"
                :signal="round_phase == 'results'"
                :mobile="mobile"/>
          </template>
        </Modal>
        <div class="scroll-content"
          :class="{
            'full-web': !isIntegration}">
          <div class="game-content">
            <div class="main spacer-h">
              <GamesHeader
                :showBackButton="!isIntegration && !isMob"
                :showBalance="showBalance"
                showInfoButton
                @ShowInfo="toggleModal"></GamesHeader>
              <Visualization :config="config">
              </Visualization>
              <NotificationBar />
              <div class="schedule-wrapper">
                <ScheduleWrapper/>
              </div>
              <div class="spacer-v">
                <Betting :mobile="mobile">
                </Betting>
              </div>
            </div>
            <BetslipArea />
          </div>
        </div>
      </Scrollable>
    </div>
    <FullScreen v-if="isMob && isUserLoggedIn && !isTablet"/>
  </div>
</template>

<script>
import isMobile from 'ismobilejs';
import { startCase } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
  Modal,
  Scrollable,
  Tabs,
  GamesHeader,
  FullScreen,
} from '@nsftx/games-sdk-js';
import Visualization from '@/components/visualization/Visualization';
import Betting from '@/components/betting/Betting';
import Statistics from '@/components/statistics/Statistics';
import Results from '@/components/results/Results';
import ScheduleWrapper from '@/components/schedule/ScheduleWrapper';
import NotificationBar from '@/components/common/NotificationBar';
import BetslipArea from '@/components/betslip/BetslipArea';
import ConfigApi from '@/api/config';
import { DataService } from '@/service';

export default {
  name: 'compact',
  components: {
    Scrollable,
    BetslipArea,
    Visualization,
    Betting,
    Statistics,
    Results,
    ScheduleWrapper,
    Tabs,
    NotificationBar,
    GamesHeader,
    Modal,
    FullScreen,
  },
  data() {
    return {
      showModal: false,
      tabComponents: [],
      activeTab: {},
    };
  },
  methods: {
    ...mapActions([
      'disable_betting',
    ]),
    updateTab(tab) {
      this.activeTab = tab;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    closeDetails(option) {
      if (option === 'outside') {
        this.showModal = false;
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.showModal = false;
        }, 500);
      }
    },
    loadConfig() {
      return new Promise((resolve) => {
        // load config
        ConfigApi.get(this.config.company, this.config.channel, 'Web', this.lang).then((res) => {
          this.set_product_config(DataService.parseConfig(res.data.config));
          this.createSocketConnection();
          resolve();
        });
      });
    },
    checkForDisable() {
      if (this.selected_round.eventId <= this.active_round.eventId) {
        this.disable_betting(true);
      } else {
        this.disable_betting(false);
      }
    },
  },
  computed: {
    ...mapGetters([
      'config',
      'product_config',
      'round_phase',
      'active_round',
      'selected_round',
      'mobile',
      'loaded',
      'theme',
      'translations',
      'currentRound',
      'currentScheduleRound',
    ]),
    ...mapGetters({
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    showBalance() {
      if ('showBalance' in this.config.ui.config) {
        return this.config.ui.config.showBalance;
      }
      return true;
    },
    isIntegration() {
      if (this.config.ui.config.mode
        && (this.config.ui.config.mode === 'integration'
        || this.config.ui.config.mode === 'standalone')) {
        return true;
      }
      return false;
    },
    components() {
      return [
        {
          id: 0,
          key: 0,
          name: 'Statistics',
          label: this.translations.general_statistics,
          component: Statistics,
          disabled: false,
        },
        {
          id: 1,
          key: 1,
          name: 'Results',
          label: this.translations.general_results,
          component: Results,
          disabled: false,
        },
      ];
    },
    component() {
      return this.activeTab;
    },
    isMob() {
      return isMobile().any || window.innerWidth <= 660;
    },
    isTablet() {
      return isMobile().tablet;
    },
    productName() {
      return startCase(this.config.productName);
    },
    roundLabel() {
      return this.translations.general_round;
    },
  },
  created() {
    this.tabComponents = this.components;
  },
};

</script>

<style scoped lang="scss">
  ::v-deep .betslip-footer.layout {
    display: unset !important;
  }
  .main-container {
    max-width: 848px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding-top: 8px;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
  .spacer-h {
    padding: 0 8px;
  }

  .spacer-v {
    padding: 8px 0;
  }
  .game-content {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    background-color: var(--background-1);

    .main {
      flex-grow: 5;
      .schedule-wrapper {
        padding-top: 8px;
      }
    }
    .sidebar {
      flex-grow: 1;
      padding-right: 8px;
    }
  }

  .content {
    width: 100%;
    .scroll-content {
      max-width: 848px;
      &.full-web {
        max-height: 100vh;
        overflow-y: scroll;
        scrollbar-width: none;
      }
    }
  }
  ::v-deep .cancel-ticket {
    background-color: rgba(0, 0, 0, 0.7);
    .modal {
      .modal-header {
        text-align: left;
        height: 60px;
        h3 {
          font-size: 24px;
          margin: 0px;
        }
      }
      .modal-body {
        text-align: left;
        font-size: 16px;
      }
      .modal-footer {
        height: 71px;
      }
    }
    button {
      font-family: Roboto !important;
    }
  }
  ::v-deep .modal-back.events-container {
      //  max-width: 832px;
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      .modal {
        max-width: 832px;
        position: absolute;
        top: 16px;
        background-color: var(--card);
        padding: 0;
        .modal-header {
          background-color: var(--button);
          height: 100px;
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 19px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          overflow-y: scroll;
          scrollbar-width: none;
          ::-webkit-scrollbar {
            width: 0 !important;
          }
          .tabs {
            .empty {
              width: 100%;
            }
          }
        }
        .modal-footer {
          display: none;
          padding-top: 0px;
        }
      }
    }
  @media (min-width: 1468px) {
    .game-content {
      max-width: 1184px;
      background: #1a1c1d;
    }

    .sidebar {
      max-width: 376px;
      width: 376px;
    }
  }

  @media (min-width: 1268px) and (max-width: 1468px) {
    .sidebar {
      width: 304px;
    }
  }

  @media (min-width: 960px) and (max-width: 1268px) {
    .game-content {
      flex-flow: column;
    }
  }

  @media (max-width: 960px) {
    .game-content {
      flex-flow: column;
      padding-bottom: 100px;
    }
  }
  @media (max-width: 420px) {
    .main-container {
      padding-top: 0px;
    }
    ::v-deep .modal-footer {
      padding-right: 10px;
    }
    .spacer-h {
      padding: 0px;
    }
    .icon {
      position: absolute;
      right: 16px !important;
      top: 5px !important;
      color: var(--text-primary-1);
      cursor: pointer;
      margin-left: auto;
      font-size: 18px;
    }
    ::v-deep .cancel-ticket {
      .modal-back.cancel-ticket {
        .modal {
          width: 100% !important;
          .modal-header {
            line-height: 40px;
          }
        }
      }
    }
  }
</style>
