<template>
  <div class="main-container" v-if="loaded">
    <div class="game-content">
      <div class="main-wrapper">
        <div class="main">
          <Modal :fullScreen="isMob"
            :showFooter="true"
            class="events-container"
            width="100%"
            height="100%"
            v-if="showModal"
            @closeModal="closeDetails"
            @closeModalOutside="closeDetails('outside')"
            ref="modal">
            <template #header>
              <div class="info">
                <div class="product-info">{{ productName }}</div>
                <div class="round-info"><span>{{ roundLabel }}
                  </span>{{ currentScheduleRound }}</div>
                <i class="icon icon-close-a"
                  @click="closeDetails" />
              </div>
            </template>
            <template #body>
              <Tabs :tabs="tabComponents"
                :activeTab="activeTab"
                v-bind="$attrs"
                @change="updateTab"></Tabs>
              <component v-bind:is="component.component"
                  v-bind="$attrs"
                  :config_bets="product_config.bets"
                  :signal="round_phase == 'results'"
                  :mobile="mobile"/>
            </template>
          </Modal>
          <div class="top-bar">
            {{ productNameLabel }}
            <span v-if="showCountdown" class="next-round">{{ nextRound }} </span>
            <span v-if="showCountdown" class="countdown">{{ minutes }}:{{ seconds }}</span>
            <span v-if="showRoundInProgress" class="next-round">
              {{ inProgressLabel }}
            </span>
            <i class="icon icon-info info-button"
              @click="toggleModal">
            </i>
          </div>
          <NotificationBar />
          <div class="schedule-wrapper">
            <ScheduleWrapper/>
            <FloatBetslipButton class="float-button"/>
          </div>
          <div class="spacer-v">
            <Betting :mobile="mobile">
            </Betting>
          </div>
        </div>
      </div>
      <div class="betslip-wrapper"
        :class="{ show: showBetslip }">
        <BetslipArea :betslipLockedMessage="betslipMessage"/>
      </div>
    </div>
  </div>
</template>

<script>
import isMobile from 'ismobilejs';
import { startCase } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
  Modal,
  Scrollable,
  Tabs,
  GamesHeader,
  FullScreen,
  eventBus,
} from '@nsftx/games-sdk-js';
import Visualization from '@/components/visualization/Visualization';
import Betting from '@/components/betting/BettingTerminal';
import Statistics from '@/components/statistics/Statistics';
import Results from '@/components/results/Results';
import Help from '@/components/help/GameHelp';
import FloatBetslipButton from '@/components/betslip/FloatBetslipButton';
import ScheduleWrapper from '@/components/schedule/ScheduleWrapper';
import NotificationBar from '@/components/common/NotificationBar';
import BetslipArea from '@/components/betslip/BetslipTerminal';
import ConfigApi from '@/api/config';
import { DataService } from '@/service';

export default {
  name: 'compact',
  components: {
    Scrollable,
    BetslipArea,
    Visualization,
    Betting,
    Statistics,
    Results,
    Help,
    FloatBetslipButton,
    ScheduleWrapper,
    Tabs,
    NotificationBar,
    GamesHeader,
    Modal,
    FullScreen,
  },
  data() {
    return {
      showModal: false,
      showBalance: true,
      tabComponents: [],
      activeTab: {},
      showCountdown: false,
      showRoundInProgress: false,
      inProgressLabel: '',
      minutes: null,
      seconds: null,
      showBetslip: false,
      betslipMessage: '',
    };
  },
  watch: {
    betslipLockMessage(val) {
      this.betslipMessage = val;
    },
  },
  methods: {
    ...mapActions([
      'disable_betting',
    ]),
    setInProgressLabel() {
      this.inProgressLabel = `${this.translations.general_round} ${this.inProgressRound} ${(this.translations.general_in_progress).toLowerCase()}`;
    },
    startCountdown() {
      const CT = Math.round((new Date()).getTime());
      const timeDiff = this.nextRoundTime - CT;
      const date = new Date(timeDiff);
      if (timeDiff <= 1) {
        this.showCountdown = false;
        this.setInProgressLabel();
        this.showRoundInProgress = true;
      } else {
        this.showRoundInProgress = false;
        this.showCountdown = true;
      }
      this.minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      this.seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    },
    updateTab(tab) {
      this.activeTab = tab;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    closeDetails(option) {
      if (option === 'outside') {
        this.showModal = false;
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.showModal = false;
        }, 500);
      }
      this.handleShowStats();
    },
    loadConfig() {
      return new Promise((resolve) => {
        // load config
        ConfigApi.get(this.config.company, this.config.channel, 'Web', this.lang).then((res) => {
          this.set_product_config(DataService.parseConfig(res.data.config));
          this.createSocketConnection();
          resolve();
        });
      });
    },
    checkForDisable() {
      if (this.selected_round.eventId <= this.active_round.eventId) {
        this.disable_betting(true);
      } else {
        this.disable_betting(false);
      }
    },
    handleShowStats() {
      this.activeTab = {
        id: 0,
        key: 0,
        name: 'Statistics',
        label: this.translations.general_statistics,
        component: Statistics,
        disabled: false,
      };
    },
  },
  computed: {
    ...mapGetters([
      'config',
      'product_config',
      'round_phase',
      'active_round',
      'schedule',
      'selected_round',
      'mobile',
      'loaded',
      'theme',
      'translations',
      'currentRound',
      'currentScheduleRound',
      'nextRoundTime',
      'betslipLockMessage',
      'inProgressRound',
    ]),
    ...mapGetters({
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    components() {
      return [
        {
          id: 0,
          key: 0,
          name: 'Statistics',
          label: this.translations.general_statistics,
          component: Statistics,
          disabled: false,
        },
        {
          id: 1,
          key: 1,
          name: 'Results',
          label: this.translations.general_results,
          component: Results,
          disabled: false,
        },
        {
          id: 2,
          key: 2,
          name: 'Help',
          label: this.translations.general_help,
          component: Help,
          disabled: false,
        },
      ];
    },
    component() {
      return this.activeTab;
    },
    isMob() {
      return isMobile().any || window.innerWidth <= 660;
    },
    productName() {
      return startCase(this.config.productName);
    },
    roundLabel() {
      return this.translations.general_round;
    },
    productNameLabel() {
      return this.translations.pages_player_history_virtual_penalty_shootout;
    },
    nextRound() {
      return this.translations.commercial_countdown_progress;
    },
  },
  created() {
    this.tabComponents = this.components;
  },
  mounted() {
    setInterval(this.startCountdown, 1000);
    this.$root.$on('ShowBetslipTerminal', () => {
      this.showBetslip = true;

      this.$nextTick(() => {
        eventBus.$emit('BetslipOpened');
      });
    });
    eventBus.$on('MinimizeBetslip', () => {
      this.showBetslip = false;
    });
  },
};

</script>

<style scoped lang="scss">
  ::v-deep .betslip-bets {
    padding-bottom: 10px;
  }
  .main-container {
    width: 100%;
    display: flex;
    margin: 0 auto;
    height: 100vh;
    overflow: hidden;
    ::-webkit-scrollbar {
      width: 0;
    }
    .game-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .main-wrapper {
        max-width: 1456px;
        width: 100%;
        margin: auto;
        .main {
          background-color: var(--background-1);
          padding: 8px;
          position: relative;
          .top-bar {
            position: relative;
            background-color: var(--card);
            height: 56px;
            border-radius: 2px;
            line-height: 56px;
            font-size: 24px;
            font-family: 'Roboto';
            color: var(--text-primary-1);
            padding-left: 20px;
            .back-button {
              padding-left: 28px;
              padding-right: 36px;
            }
            .next-round {
              color: var(--text-primary-2);
            }
            .countdown {
              color: var(--secondary);
            }
            .info-button {
              cursor: pointer;
              color: var(--text-primary-2);
              position: absolute;
              font-size: 24px;
              transition: all 0.3s ease;
              &:hover {
                color: var(--text-primary-1);
              }
            }
          }
          .schedule-wrapper {
            margin: 8px 0px;
            display: flex;
            .float-button {
              display: none;
            }
          }
        }
      };
      .betslip-wrapper {
        width: 400px;
      }
    }
  }
  .spacer-h {
    padding: 0 8px;
  }

  .spacer-v {
    padding: 0;
  }
  ::v-deep .cancel-ticket {
    background-color: rgba(0, 0, 0, 0.7);
    .modal {
      .modal-header {
        text-align: left;
        height: 60px;
        h3 {
          font-size: 24px;
          margin: 0px;
        }
      }
      .modal-body {
        text-align: left;
        font-size: 16px;
      }
      .modal-footer {
        height: 71px;
      }
    }
    button {
      font-family: Roboto !important;
    }
  }
  ::v-deep .modal-back.events-container {
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      .modal {
        max-width: 100%;
        position: absolute;
        background-color: var(--card);
        padding: 0;
        height: 100%;
        .modal-header {
          background-color: var(--button);
          height: 100px;
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 19px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          overflow-y: scroll;
          .tabs {
            font-size: 16px;
            .empty {
              width: 100%;
            }
          }
          .statistics {
            .desktop {
              .wrap-top {
                display: flex;
                .field-group {
                  .statistics-group-field {
                    .title {
                      font-size: 16px;
                    }
                    .content {
                      .field-wrap {
                        .field {
                          width: 56px;
                          height: 50px;
                          line-height: 50px;
                          font-size: 18px;
                        }
                        .label {
                          font-size: 16px;
                        }
                      }
                    }
                  }
                }
              }
              .wrap-main {
                display: flex;
                justify-content: space-around;
                .wrap-left,
                .wrap-right {
                  max-width: 330px;
                  .label {
                    font-size: 16px;
                  }
                }
              }
            }
          }
          .results {
            .wrap-left {
              width: 60%;
              display: inline-block;
            }
            .wrap-right {
              width: 35%;
              display: inline-block;
              .results-group {
                margin-top: 20px;
                padding-left: 20px;
              }
            }
          }
        }
        .modal-footer {
          display: none;
          padding-top: 0px;
        }
      }
    }
    @media (max-width: 1024px) {
  ::v-deep .modal-back.events-container {
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      .modal {
        max-width: 100%;
        position: absolute;
        background-color: var(--card);
        padding: 0;
        height: 100%;
        .modal-header {
          background-color: var(--button);
          height: 100px;
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 19px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          overflow-y: scroll;
          padding: 0;
          .tabs {
            font-size: 16px;
            .tab-button {
              max-width: unset;
              &.active:hover {
                button {
                  background-color: var(--card);
                }
              }
            }
            .empty {
              width: 0px;
            }
          }
          .statistics {
            .desktop {
              .wrap-top {
                display: flex;
                justify-content: space-between;
                .field-group {
                  .statistics-group-field {
                    .title {
                      font-size: 16px;
                    }
                    .content {
                      .field-wrap {
                        .field {
                          width: 56px;
                          height: 50px;
                          line-height: 50px;
                          font-size: 18px;
                        }
                        .label {
                          font-size: 16px;
                        }
                      }
                    }
                  }
                }
              }
              .wrap-main {
                display: flex;
                justify-content: space-around;
                .wrap-left,
                .wrap-right {
                  max-width: 330px;
                  .label {
                    font-size: 16px;
                  }
                  .value {
                    font-size: 16px;
                    line-height: 16px;
                  }
                }
              }
            }
          }
          .results {
            .wrap-left {
              width: 50%;
              display: inline-block;
            }
            .wrap-right {
              width: 45%;
              display: inline-block;
              .results-group {
                margin-top: 20px;
                padding-left: 20px;
              }
            }
            .results-group-field {
              &.size-8 {
                margin-right: 2px;
              }
            }
          }
        }
        .modal-footer {
          display: none;
          padding-top: 0px;
        }
      }
    }
  .main-container {
    .game-content {
      .main-wrapper {
        max-width: 880px;
        .main {
          .top-bar {
            position: relative;
            background-color: var(--card);
            height: 54px;
            border-radius: 2px;
            line-height: 54px;
            font-size: 20px;
            font-family: 'Roboto';
            color: var(--text-primary-1);
            .info-button {
              top: 15px;
              right: 15px;
              font-size: 20px;
            }
          }
          .schedule-wrapper {
            .float-button {
              margin: 8px 0px;
              display: flex;
              display: block;
            }
          }
        }
      };
      .betslip-wrapper {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        &.show {
          display: block;
        }
        .betslip-area {
          //  width: 356px;
          ::v-deep .betslip {
            width: 356px;
          }
        }
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1366px) {
  .main-container {
    .game-content {
      .betslip-wrapper {
        width: 356px;
        ::v-deep .predefinedStake {
          min-width: 105px;
        }
      }
    }
  }
  ::v-deep .modal-back.events-container {
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      .modal {
        max-width: 100%;
        position: absolute;
        background-color: var(--card);
        padding: 0;
        height: 100%;
        .modal-header {
          background-color: var(--button);
          height: 100px;
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 19px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          overflow-y: scroll;
          padding: 0;
          .tabs {
            font-size: 16px;
            .tab-button {
              max-width: unset;
              &.active:hover {
                button {
                  background-color: var(--card);
                }
              }
            }
            .empty {
              width: 0px;
            }
          }
          .statistics {
            .desktop {
              .wrap-top {
                display: flex;
                .field-group {
                  .statistics-group-field {
                    .title {
                      font-size: 16px;
                    }
                    .content {
                      .field-wrap {
                        .field {
                          width: 56px;
                          height: 50px;
                          line-height: 50px;
                          font-size: 18px;
                        }
                        .label {
                          font-size: 16px;
                        }
                      }
                    }
                  }
                }
              }
              .wrap-main {
                display: flex;
                justify-content: space-around;
                .wrap-left,
                .wrap-right {
                  max-width: 330px;
                  .label {
                    font-size: 16px;
                  }
                  .value {
                    font-size: 16px;
                    line-height: 16px;
                  }
                }
              }
            }
          }
          .results {
            .wrap-left {
              width: 50%;
              display: inline-block;
            }
            .wrap-right {
              width: 45%;
              display: inline-block;
              .results-group {
                margin-top: 20px;
                padding-left: 20px;
              }
            }
            .results-group-field {
              &.size-8 {
                margin-right: 2px;
              }
            }
          }
        }
        .modal-footer {
          display: none;
          padding-top: 0px;
        }
      }
    }
  .main-container {
    .game-content {
      .main-wrapper {
        max-width: 880px;
        .main {
          .top-bar {
            position: relative;
            background-color: var(--card);
            height: 54px;
            border-radius: 2px;
            line-height: 54px;
            font-size: 20px;
            font-family: 'Roboto';
            color: var(--text-primary-1);
            .info-button {
              top: 15px;
              right: 15px;
              font-size: 20px;
            }
          }
        }
      };
      .betslip-wrapper {
        .betslip-area {
          //  width: 356px;
          ::v-deep .betslip {
            width: 356px;
          }
        }
      }
    }
  }
}
@media (min-width: 1367px) and (max-width: 1680px) {
  .main-container {
    .game-content {
      .main-wrapper {
        max-width: 1020px;
        .main {
          .top-bar {
            position: relative;
            background-color: var(--card);
            height: 54px;
            border-radius: 2px;
            line-height: 54px;
            font-size: 24px;
            font-family: 'Roboto';
            color: var(--text-primary-1);
            .info-button {
              top: 15px;
              right: 15px;
            }
          }
        }
      };
    }
  }
  ::v-deep .modal-back.events-container {
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      .modal {
        max-width: 100%;
        position: absolute;
        background-color: var(--card);
        padding: 0;
        height: 100%;
        .modal-header {
          background-color: var(--button);
          height: 100px;
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 19px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          padding: 0;
          overflow-y: scroll;
          .tabs {
            height: 68px;
            .tab-button {
              max-width: unset;
              height: 54px;
              button {
                font-size: 18px;
                &.active:hover {
                  background-color: var(--card);
                }
              }
            }
            font-size: 16px;
            .empty {
              width: 0;
            }
          }
          .statistics {
            .desktop {
              .wrap-top {
                display: flex;
                .field-group {
                  .statistics-group-field {
                    .title {
                      font-size: 16px;
                    }
                    .content {
                      .field-wrap {
                        .field {
                          width: 70px;
                          height: 54px;
                          line-height: 54px;
                          font-size: 18px;
                        }
                        .label {
                          font-size: 16px;
                        }
                      }
                    }
                  }
                }
              }
              .wrap-main {
                display: flex;
                justify-content: space-evenly;
                .wrap-left,
                .wrap-right {
                  max-width: 420px;
                  width: 100%;
                  .label {
                    height: 16px;
                    font-size: 16px;
                  }
                  .title {
                    font-size: 16px;
                  }
                  .value {
                    height: 16px;
                    font-size: 16px;
                    line-height: 16px;
                  }
                }
                .label-left-wrap {
                  .label {
                    margin-bottom: 9px;
                  }
                }
              }
            }
          }
          .results {
            .wrap-top {
              .dropdown {
                height: 54px;
                font-size: 18px;
              }
            }
            .wrap-left {
              width: 50%;
              float: left;
              .title {
                font-size: 16px;
              }
              .results-group-field {
                width: 60px;
                height: 54px;
                font-size: 18px;
                margin: 0 2px 2px 0;
                &.size-3 {
                  width: 164px;
                }
              }
            }
            .wrap-right {
              width: 50%;
              display: inline-block;
              .title {
                font-size: 16px;
              }
              .results-group {
                margin-top: 24px;
                padding-left: 20px;
                .title {
                  padding-bottom: 11.5px;
                }
              }
              .results-group-field {
                width: 49%;
                font-size: 18px;
                margin-right: 1%;
                height: 54px;
                &.size-3 {
                  width: 154px;
                }
              }
            }
          }
        }
        .modal-footer {
          display: none;
          padding-top: 0px;
        }
      }
    }
}
@media (min-width: 1681px) and (max-width: 1920px) {
  ::v-deep .modal-back.events-container {
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      .modal {
        max-width: 100%;
        position: absolute;
        background-color: var(--card);
        padding: 0;
        height: 100%;
        .modal-header {
          background-color: var(--button);
          height: 100px;
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 28px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 20px;
              line-height: 20px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 19px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          padding: 0;
          overflow-y: scroll;
          .tabs {
            height: 68px;
            .tab-button {
              max-width: unset;
              height: 68px;
              button {
                font-size: 24px;
                &.active:hover {
                  background-color: var(--card);
                }
              }
            }
            font-size: 16px;
            .empty {
              width: 0;
            }
          }
          .statistics {
            .desktop {
              .wrap-top {
                display: flex;
                .field-group {
                  .statistics-group-field {
                    .title {
                      font-size: 20px;
                    }
                    .content {
                      .field-wrap {
                        .field {
                          width: 80px;
                          height: 72px;
                          line-height: 72px;
                          font-size: 28px;
                        }
                        .label {
                          font-size: 20px;
                        }
                      }
                    }
                  }
                }
              }
              .wrap-main {
                display: flex;
                justify-content: space-evenly;
                .wrap-left,
                .wrap-right {
                  max-width: 510px;
                  width: 100%;
                  .label {
                    height: 20px;
                    font-size: 20px;
                  }
                  .title {
                    font-size: 20px;
                  }
                  .value {
                    height: 20px;
                    line-height: 20px;
                    font-size: 20px;
                  }
                }
              }
            }
          }
          .results {
            .wrap-top {
              .dropdown {
                height: 72px;
                font-size: 24px;
              }
            }
            .wrap-left {
              width: 50%;
              display: inline-block;
              .title {
                font-size: 20px;
              }
              .results-group-field {
                width: 83px;
                height: 72px;
                font-size: 24px;
                margin: 0 2px 2px 0;
                &.size-3 {
                  width: 225px;
                }
              }
            }
            .wrap-right {
              width: 50%;
              display: inline-block;
              .content {
                margin-top: 3px;
              }
              .title {
                font-size: 20px;
              }
              .results-group {
                margin-top: 25px;
                padding-left: 20px;
                .title {
                  padding-bottom: 12px;
                }
              }
              .results-group-field {
                width: 49%;
                font-size: 24px;
                margin-right: 1%;
                height: 72px;
                &.size-3 {
                  width: 225px;
                }
              }
            }
          }
        }
        .modal-footer {
          display: none;
          padding-top: 0px;
        }
      }
    }
  .main-container {
    .game-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .main-wrapper {
        max-width: 1456px;
        width: 100%;
        margin: auto;
        .main {
          background-color: var(--background-1);
          padding: 8px;
          position: relative;
          .top-bar {
            height: 72px;
            line-height: 72px;
            .back-button {
              padding-left: 28px;
              padding-right: 36px;
            }
            .info-button {
              right: 24px;
              top: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
