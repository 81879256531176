var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-info-help-wrapper", style: { height: _vm.height } },
    [_c("Help", { attrs: { "help-page": _vm.helpPage } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }