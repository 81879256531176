var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "terminal-results-wrapper" },
        [
          _c(
            "Results",
            _vm._b(
              {
                attrs: {
                  config_bets: _vm.product_config.bets,
                  signal: _vm.round_phase == "results",
                },
              },
              "Results",
              _vm.$attrs,
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }