var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "terminal-ticket-details-wrapper" },
    [
      _vm.loaded && _vm.ticketData
        ? _c("TerminalTicketDetails", {
            attrs: {
              ticket: _vm.ticketData,
              cancelEnabled: _vm.cancelEnabled,
              rebetEnabled: _vm.rebetEnabled,
            },
            on: {
              payout: function ($event) {
                return _vm.handlePayout(_vm.rawTicketData)
              },
              cancel: function ($event) {
                return _vm.handleCancel(_vm.rawTicketData)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }