<template>
  <div class="results-group">
    <div class="title">
      {{ title | translate}} {{ formatOdds }}
    </div>
    <div class="content">
      <ResultsGroupField v-for="(item, i) in fields"
                         :key="i"
                         :value="item"
                         :prefix="prefix"
                         :type="type"
                         :active="item.key ? i+1 == result : item == result"/>
    </div>
  </div>
</template>

<script>

import ResultsGroupField from '@/components/results/ResultsGroupField';

export default {
  name: 'ResultsGroup',

  components: {
    ResultsGroupField,
  },

  props: {
    title: {
      type: String,
      default: 'title',
    },
    odds: {
      type: [Number, String],
      default: 0,
    },
    fields: {
      type: [Number, Array],
      default: 0,
    },
    prefix: {
      type: [String, Boolean],
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    result: {
      type: [Number, String, Boolean],
    },
  },

  computed: {
    formatOdds() {
      // move to filter
      return `(${this.odds}.00)`;
    },
  },
};
</script>

<style scoped lang="scss">

.results-group {
  margin-top: 23px;

  .title {
    text-align: center;
    padding-bottom: 12px;
  }
}

@media (min-width: $break-small) and (max-width: $break-medium-extra){
  .results-group {
    margin-top: 16px;
  }
}

</style>
