<template>
  <div class="betslip-area">
    <GamesBetslipD bgImage="../assets/empty-betslip.svg" :showMinimizeButton="true"></GamesBetslipD>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GamesBetslipD } from '@nsftx/games-sdk-js';
import isMobile from 'ismobilejs';

export default {
  name: 'BetslipArea',
  components: {
    GamesBetslipD,
  },
  props: {
    betslipLockedMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'config',
      'user/user',
      'translations',
      'appLayout',
    ]),
    ...mapGetters('betslip', [
      'tickets',
    ]),
    betslipComponent() {
      if (this.appLayout === 'Compact') {
        return 'BetslipB';
      }
      return this.isMob() ? 'BetslipB' : 'BetslipA';
    },
    userProfile() {
      return !this['user/user'];
    },
    betslipTypes() {
      return [
        {
          id: 0,
          key: 0,
          type: 3,
          value: 'single',
          label: this.translations.general_ticket_single,
          active: true,
        },
      ];
    },
    showBetCounter() {
      if (this.tickets.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('betslip',
      ['setBetslipConfigValues']),
    isMob() {
      return isMobile().any || window.innerWidth <= 960;
    },
  },
  created() {
    const betslipConfig = {
      showPossibleWin: true,
    };
    this.setBetslipConfigValues(betslipConfig);
  },
};
</script>

<style scoped lang="scss">
::v-deep .betslipDWrapper {
  div {
    .betslipFooter {
      .betslipFooterLower {
        .possibleWinWrapper {
          .payOut {
            display: none;
          }
        }
      }
    }
  }
}
::v-deep .header {
  .remove {
    z-index: 100;
  }
}
::v-deep .overlay {
  left: 0px;
}
::v-deep .betslip-footer {
  .input-wrapper {
    display: unset !important;
  }
}
::v-deep .notification-wrapper {
  .notification {
    span {
      height: 100%;
      display: flex;
      align-items: center;
      i {
        margin-right: 5px;
      }
    }
  }
}
</style>
