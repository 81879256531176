var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["statistics clearfix", _vm.theme] }, [
    _vm.mobile
      ? _c("div", { staticClass: "mobile" }, [
          _c(
            "div",
            [
              _c("Dropdown", {
                attrs: {
                  items: _vm.arrayItems,
                  value: "key",
                  translate: true,
                  limit: 8,
                },
                on: { dropdownSelect: _vm.select },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "selected" }, [
            _vm.selected.type == "field"
              ? _c("div", { staticClass: "wrap-top" }, [
                  _c(
                    "div",
                    { staticClass: "field-group" },
                    [
                      _c("StatisticsGroupField", {
                        attrs: {
                          title: _vm.markets.field.hot.key,
                          stats: _vm.statistics.field.hot,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field-group right" },
                    [
                      _c("StatisticsGroupField", {
                        attrs: {
                          title: _vm.markets.field.cold.key,
                          stats: _vm.statistics.field.cold,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("div", { staticClass: "wrap-main" }, [
                  _c(
                    "div",
                    { staticClass: "field-group-progress" },
                    [
                      _c("StatisticsGroupProgress", {
                        attrs: {
                          title: _vm.selected.key,
                          type: _vm.selected.type,
                          fields: _vm.selected.prefix || _vm.selected.outcomes,
                          stats: _vm.statistics[_vm.selected.type],
                        },
                      }),
                    ],
                    1
                  ),
                ]),
          ]),
        ])
      : _c("div", { staticClass: "desktop" }, [
          _c("div", { staticClass: "wrap-top" }, [
            _c(
              "div",
              { staticClass: "field-group" },
              [
                _c("StatisticsGroupField", {
                  attrs: {
                    title: _vm.markets.field.hot.key,
                    stats: _vm.statistics.field.hot,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "field-group right" },
              [
                _c("StatisticsGroupField", {
                  attrs: {
                    title: _vm.markets.field.cold.key,
                    stats: _vm.statistics.field.cold,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "wrap-main" }, [
            _c(
              "div",
              { staticClass: "wrap-left" },
              _vm._l(_vm.statsLeftGroup, function (item, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    item.active
                      ? [
                          _c(
                            "div",
                            { staticClass: "field-group-progress" },
                            [
                              _c("StatisticsGroupProgress", {
                                attrs: {
                                  title: item.key,
                                  type: item.type,
                                  fields: item.prefix || item.outcomes,
                                  stats: _vm.statistics[i],
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "wrap-right" },
              _vm._l(_vm.statsRightGroup, function (item, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    item.active
                      ? [
                          _c(
                            "div",
                            { staticClass: "field-group-progress" },
                            [
                              _c("StatisticsGroupProgress", {
                                attrs: {
                                  title: item.key,
                                  type: item.type,
                                  fields: item.prefix || item.outcomes,
                                  stats: _vm.statistics[i],
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }