import axios from 'axios';
import ngsRoute from '../utility/getDc';

export default {
  get: (company, channel) => {
    const api = ngsRoute.getDc();

    return axios.get(`${api}/${process.env.VUE_APP_NGS_RESULTS_PATH}?product=virtualpenaltyshootout`, {
      headers: {
        'X-Nsft-Ngs-Company': company,
        'X-Nsft-Ngs-Product': channel,
      },
    });
  },
};
