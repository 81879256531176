var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "float-betslip-button", on: { click: _vm.showBetslip } },
    [
      _c("div", { staticClass: "float-betslip-button-content center-xy" }, [
        _c("i", {
          staticClass: "n-i n-i-betslip",
          style: {
            color: _vm.ticketLength
              ? "var(--secondary)"
              : "var(--text-primary-3)",
          },
        }),
        _vm.ticketLength
          ? _c("div", { style: { backgroundColor: "var(--secondary)" } }, [
              _vm.ticketLength
                ? _c("span", { staticClass: "center-xy" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.ticketLength) + "\n      "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }