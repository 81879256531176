var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "statistics-group-progress" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm._f("translate")(_vm.title))),
    ]),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "label-left-wrap", class: { terminal: _vm.isTerminal } },
        _vm._l(_vm.stats, function (item, i) {
          return _c("div", { key: i, staticClass: "label" }, [
            _vm.isPrefixed
              ? _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("firstLetter")(_vm._f("translate")(_vm.fields))
                      ) +
                      _vm._s(item.label) +
                      "\n        "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("translate")(_vm.fields[i].key)) +
                      "\n        "
                  ),
                ]),
          ])
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "progress-middle-wrap",
          class: { terminal: _vm.isTerminal },
        },
        _vm._l(_vm.stats, function (item, i) {
          return _c(
            "div",
            { key: i },
            [
              _c("StatisticsGroupProgressBar", {
                attrs: { value: item.value, type: _vm.type, num: i },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "value-right-wrap",
          class: { terminal: _vm.isTerminal },
        },
        _vm._l(_vm.stats, function (item, i) {
          return _c("div", { key: i, staticClass: "value" }, [
            _vm._v("\n        " + _vm._s(parseInt(item.value)) + "%\n      "),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }