<template>
  <div class="boost-manager">
    <div id="boostBadgeContainer"
      v-if="isMobileDeviceDevicePhone"
      :class="{'mobile-ios': !isIntegration &&
        (config.applicationName === 'Mobile' && isMobileDeviceIos)}"></div>
    <div
      id="boostContainer"
      :class="{ isTablet: isTablet }"
    />
  </div>
</template>

<script>
import isMobileDevice from 'ismobilejs';
import { mapGetters } from 'vuex';
import { eventBus } from '@nsftx/games-sdk-js';

export default {
  name: 'Boost',
  computed: {
    ...mapGetters([
      'user/user',
      'isMobile',
      'config',
      'isAuthChanged',
    ]),
    userProfile() {
      return this['user/user'];
    },
    isIntegration() {
      if (this.config.ui.config.mode
        && (this.config.ui.config.mode === 'integration' || this.config.ui.config.mode === 'standalone')) {
        return true;
      }
      return false;
    },
    isMobileDeviceIos() {
      return isMobileDevice().apple.device;
    },
    isMobileDeviceDevicePhone() {
      return isMobileDevice().any;
    },
    isTablet() {
      return isMobileDevice().tablet;
    },
  },
  methods: {
    configureBoost() {
      const boostConfig = this.config.ui.config.boost;
      const boostPayload = {
        tenantId: boostConfig.tenantId,
        modulesTheme: boostConfig.theme,
        environment: process.env.NODE_ENV,
        modulesContainerId: 'boostContainer',
        authorizationToken: this.userProfile.auth.token,
        widget: this.isMobileDeviceDevicePhone ? 'virtualsMobile' : 'virtuals',
        badgeSize: 'small',
        language: this.config.locale,
      };
      if (this.userProfile.profile.id) {
        boostPayload.profileId = this.userProfile.profile.id;
      }

      if (this.isMobile) {
        boostPayload.badgeContainerId = 'boostBadgeContainer';
      }
      window.NSoftBoost.configure(boostPayload);
    },
  },
  mounted() {
    if (this.isAuthChanged) {
      this.configureBoost();
    }
    eventBus.$on('InitBoost', () => {
      this.configureBoost();
    });
  },
  beforeDestroy() {
    eventBus.$off('InitBoost', this.configureBoost);
  },
};
</script>

<style lang="scss" scoped>
.boost-manager {
  flex: 1;
  #boostBadgeContainer {
    position: absolute;
    display: flex;
    align-items: center;
    top: 5px;
    left: 80px;
    &.mobile-ios {
      left: 40px;
    }
  }
  #boostContainer {
    height: 100%;
  }
}

@media (max-width: 640px) {
  .boost-manager {
    #boostContainer {
      float: left;
      width: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      z-index: 100;
    }
    ::v-deep #boostBadgeContainer {
      #NSoftBoostBadgeIframe {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
@media (min-width: 640px) and (max-width: 1200px) {
  .boost-manager {
    #boostContainer {
      height: 720px;
    }
  }
}
</style>
