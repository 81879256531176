<template>
  <div class="sch-wrap" :class="{terminal: isTerminal}">
    <Schedule
      v-if="!isTerminal"
      :offer="schedule"
      :isTerminal="isTerminal"
      :roundLabel="roundLabel"
      ref="schedule"
      @selectedRound="selectRound"></Schedule>
    <ScheduleTerminal
      v-if="isTerminal"
      :offer="schedule"
      :roundLabel="roundLabel"
      ref="schedule"
      @selectedRound="selectRound"></ScheduleTerminal>
  </div>
</template>

<script>
import { find } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { Schedule } from '@nsftx/games-sdk-js';
import ScheduleTerminal from './ScheduleTerminal';

export default {
  name: 'ScheduleWrapper',
  components: {
    Schedule,
    ScheduleTerminal,
  },
  data() {
    return {
      roundActive: 0,
    };
  },
  created() {
    [this.roundActive] = this.schedule;
  },
  beforeUpdate() {
    if (this.schedule[0].eventId > this.roundActive.eventId) {
      [this.roundActive] = this.schedule;
    }
  },
  methods: {
    ...mapActions([
      'set_selected_round',
      'setCurrentRound',
    ]),
    select(round) {
      this.set_selected_round(round.eventId);
      this.roundActive = round;
    },
    selectRound(event) {
      this.setCurrentRound(event);
      const selectedRound = find(this.schedule, { eventIdToday: event });
      this.select(selectedRound);
    },
  },
  computed: {
    ...mapGetters([
      'schedule',
      'translations',
      'currentRound',
      'isTerminal',
    ]),
    roundLabel() {
      return this.translations.general_round;
    },
  },
  mounted() {
    this.$root.$on('updateSchedule', () => {
      if (this.currentRound < this.schedule[0].eventIdToday) {
        this.$refs.schedule.toggle(this.schedule[0], 0);
      } else {
        this.$refs.schedule.toggle(find(this.schedule,
          { eventIdToday: this.currentRound }), this.schedule.indexOf(find(this.schedule, {
          eventIdToday: this.currentRound,
        })));
      }
    });
    this.$root.$on('switchToNextRound', () => {
      this.$refs.schedule.toggle(find(this.schedule,
        { eventIdToday: this.currentRound + 1 }), this.schedule.indexOf(find(this.schedule, {
        eventIdToday: this.currentRound + 1,
      })));
    });
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .schedule-wrapper {
    margin: 0px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin-top: 8px;
    z-index: 0;
  }
  .sch-wrap {
    width: 100%;
    position: relative;
    height: 64px;
  }
@media all and (min-width: 1900px) {
  .sch-wrap {
    width: 100%;
    position: relative;
    height: 80px;
  }
}
</style>
