var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "visHeight", staticClass: "visualization-wrap visualization" },
    [
      _c("iframe", {
        staticClass: "iframe",
        attrs: { src: _vm.pluginSrc, frameborder: "0" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }