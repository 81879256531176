<template>
  <div>
    <div :class="['betting-market-label', theme]">{{title | translate}} {{parsedOdd}}</div>
    <div class="betting-market-bets">
      <Button v-for="(bet, i) in bets" :key="i"
        class="betting-button"
        :class="[ buttonType(bet) ]"
        :label="bet.outcome"
        :disabled="disable"
        @change="selectBet(bet)"
        :variation="isActive(bet) ? 'brand' : 'default'"></Button>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import { mapGetters } from 'vuex';
import { Button } from '@nsftx/games-sdk-js';

export default {
  name: 'BettingMarket',
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
    },
    bets: {
      type: [Array, Object],
    },
    odds: {
      type: Number,
    },
    disable: {
      type: Boolean,
    },
    select: {
      type: Function,
    },
    outcomes: {
      type: [Boolean, Array],
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    parsedOdd() {
      return `(${this.odds}.00)`;
    },
    ...mapGetters([
      'gamesBetslip/ticket',
    ]),
  },
  methods: {
    selectBet(bet) {
      if (!this.disabled) {
        this.select(bet);
      }
    },
    isActive(bet) {
      return find(this['gamesBetslip/ticket'], betOnTicket => betOnTicket.id === bet.id);
    },
    buttonType(bet) {
      const mapBetType = {
        1: 'button-normal-8',
        2: 'button-special-2',
        3: 'button-special-2',
        4: 'button-normal-3',
        5: 'button-normal-8',
        6: 'button-special-2',
        7: 'button-special-3',
      };
      return mapBetType[bet.type];
    },
  },
};
</script>

<style scoped lang="scss">
.brand {
  color: var(--custom-text-color);
}
.betting-market-label {
  text-align: center;
  color: var(--text-primary-2);
  padding-bottom: 8px;
  font-weight: 500;
}
.betting-button {
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 2px;
  border: 1px solid var(--card);
}
.button-special-3 {
  display: inline-block;
  width: 218px;
  height: 72px;
  padding: 1px;
  margin-right: 2%;
  font-size: 24px;
}

.button-special-2 {
  display: inline-block;
  width: 336px;
  height: 72px;
  padding: 1px;
  margin-right: 2%;
  font-size: 24px;
}

.button-normal-3 {
  width: 218px;
  margin-right: 2%;
  height: 72px;
  font-size: 24px;
  padding: 1px;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }
}

.button-special-2:last-child,
.button-special-3:last-child {
  margin-right: 0;
}

.button-normal-8 {
  width: 84px;
  height: 72px;
  padding: 1px;
  display: inline-block;
  font-size: 24px;
}
@media (max-width: 1024px) {
  .button-special-3 {
    display: inline-block;
    width: 111px;
    height: 54px;
    padding: 1px;
    margin-right: 2%;
    font-size: 18px;
  }
  .button-normal-3 {
    width: 162px;
    margin-right: 0.1%;
    height: 54px;
    font-size: 18px;
    padding: 1px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }
  .button-special-2 {
    display: inline-block;
    width: 170px;
    height: 54px;
    padding: 1px;
    margin-right: 2%;
    font-size: 18px;
  }
  .button-normal-8 {
    width: 61px;
    height: 54px;
    padding: 1px;
    display: inline-block;
    font-size: 18px;
  }
}
@media (min-width: 1025px) and (max-width: 1366px) {
  .button-special-3 {
    display: inline-block;
    width: 111px;
    height: 54px;
    padding: 1px;
    margin-right: 2%;
    font-size: 18px;
  }
  .button-normal-3 {
    width: 162px;
    margin-right: 0.1%;
    height: 54px;
    font-size: 18px;
    padding: 1px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }
  .button-special-2 {
    display: inline-block;
    width: 170px;
    height: 54px;
    padding: 1px;
    margin-right: 2%;
    font-size: 18px;
  }
  .button-normal-8 {
    width: 61px;
    height: 54px;
    padding: 1px;
    display: inline-block;
    font-size: 18px;
  }
}
@media (min-width: 1367px) and (max-width: 1680px) {
  .button-special-3 {
    display: inline-block;
    width: 128px;
    height: 54px;
    padding: 1px;
    margin-right: 2%;
    font-size: 18px;
  }
  .button-normal-3 {
    width: 180px;
    margin-right: 1%;
    height: 54px;
    font-size: 18px;
    padding: 1px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }
  .button-special-2 {
    display: inline-block;
    width: 197px;
    height: 54px;
    padding: 1px;
    margin-right: 2%;
    font-size: 18px;
  }
  .button-normal-8 {
    width: 69px;
    height: 54px;
    padding: 1px;
    display: inline-block;
    font-size: 18px;
  }
}
@media (min-width: 1910px) {
  .button-normal-3 {
    width: 218px;
    margin-right: 9px;
    height: 72px;
    font-size: 24px;
    padding: 1px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
