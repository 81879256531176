<template>
  <div class="statistics-group-field">
    <div class="title">
      {{ title | translate }}
    </div>
    <div class="content">
      <div class="field-wrap" v-for="(item, i) in stats"
                              :key="i">
        <div class="field">
          <span class="value">{{ item.label }}</span>
        </div>
        <div class="label">
          <span>{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'StatisticsGroupField',

  props: {
    title: {
      type: String,
      default: '',
    },
    stats: {
      type: [Object, Array],
      default: () => [],
    },
  },
};

</script>

<style scoped lang="scss">
.statistics-group-field {
  display: inline-block;

  .title {
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 9px;
    font-weight: 500;
  }

  .content {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
  }

  .field-wrap {
    display: inline-block;
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }
  }

  .field {
    width: 38px;
    height: 38px;
    border-radius: 2px;
    background-color: #c1024f;
    text-align: center;
    color: var(--custom-text-color);

    .value {
      line-height: 38px;
    }
  }

  .label {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--text-primary-2);
    padding-top: 3px;
  }
}
</style>
