var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-wrapper" },
    [
      _vm.ticketHistoryTerminal.length
        ? _c("TerminalTicketHistory", {
            attrs: {
              headers: _vm.listHeaders,
              items: _vm.listItems,
              showArrows: _vm.arrowVisible,
            },
          })
        : _c("TerminalTicketHistoryEmpty"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }