<template>
  <div
    class="game-info-help-wrapper"
    :style="{ height: height }"
  >
    <Help :help-page="helpPage"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Help, gamesStatic } from '@nsftx/games-sdk-js';

export default {
  name: 'GameHelp',
  components: {
    Help,
  },
  props: {
    config_bets: {
      type: Object,
    },
    signal: {
      type: [Number, Boolean],
    },
  },
  data() {
    return {
      helpPage: '',
    };
  },
  computed: {
    ...mapGetters(['config', 'staticTheme', 'isTerminal']),
    height() {
      return this.isMobile ? '100vh' : '500px';
    },
    isMobile() {
      return window.innerWidth < 660;
    },
  },
  async mounted() {
    const { productName, locale } = this.config;
    if (this.isTerminal) {
      const { companyData } = this.config;
      this.helpPage = await gamesStatic.getHelp(productName, locale, companyData.uuid);
    } else {
      const { tenantId } = this.config;
      this.helpPage = await gamesStatic.getHelp(productName, locale, tenantId, this.staticTheme);
    }
  },
};
</script>

<style scoped lang="scss">
.game-info-help-wrapper {
  color: var(--text-primary-1);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .game-info-section {
    display: flex;
    flex-direction: column;

    .game-info-title {
      font-weight: 700;
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
    }

    .game-info-text {
      color: var(--text-primary-2);
      font-size: 1.4rem;
    }
  }
}
</style>
