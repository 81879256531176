var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schedule-wrapper terminal" },
    [
      _c(
        "Button",
        {
          staticClass: "scroll-button left",
          attrs: { label: "" },
          on: {
            change: function ($event) {
              return _vm.swipe("left")
            },
          },
        },
        [_c("i", { staticClass: "icon icon-arrow-left" })]
      ),
      _c(
        "div",
        { ref: "content", staticClass: "content-wrapper" },
        _vm._l(_vm.offer, function (round, index) {
          return _c(
            "Button",
            {
              key: index,
              ref: "round-content",
              refInFor: true,
              staticClass: "round-wrapper",
              class: { brand: _vm.activeIndex === index },
              style: { width: `${_vm.roundWrapperWidth}px` },
              attrs: { label: "", disabled: _vm.isScheduleDisabled },
              on: {
                change: function ($event) {
                  return _vm.toggle(round, index)
                },
              },
            },
            [
              _c("div", { staticClass: "round-time" }, [
                _vm._v(_vm._s(_vm.formatTime(round.startDate.date))),
              ]),
              _c("div", { staticClass: "round-number" }, [
                _vm._v(_vm._s(`${_vm.roundLabel} ${round.eventIdToday}`)),
              ]),
            ]
          )
        }),
        1
      ),
      _c(
        "Button",
        {
          staticClass: "scroll-button right",
          attrs: { label: "" },
          on: { change: _vm.swipe },
        },
        [_c("i", { staticClass: "icon icon-arrow-right" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }