<template>
  <div :class="['results-group-field', theme, type, { active: active }]">
    <span v-if="checkFieldType">
      {{ value.key | translate }}
    </span>
    <span v-else-if="prefix">
      {{ prefix | translate | firstLetter }}{{ value }}
    </span>
    <span v-else>
      {{ value }}
    </span>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ResultsGroupField',

  props: {
    value: {
      type: [Number, Object],
      default: 0,
    },
    type: {
      type: String,
      default: 'button',
    },
    prefix: {
      type: [String, Boolean],
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters([
      'theme',
    ]),
    checkFieldType() {
      return this.value.key || false;
    },
  },
};

</script>

<style scoped lang="scss">

// move to common buttonlike component
.results-group-field {
  display: inline-block;
  width: 33px;
  height: 40px;
  background: var(--button);
  text-align: center;
  margin-bottom: 2px;
  color: var(--text-primary-2);
  border-radius: 2px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &.active {
    background: var(--primary);
    color: var(--custom-text-color);
  }

  &.size-3 {
    width: 32%;
    margin-right: 2%;

    &:last-child {
      margin-right: 0;
    }
  }
  &.size-2 {
    width: 49%;
    margin-right: 2%;

    &:last-child {
      margin-right: 0;
    }
  }
  &.size-8 {
    width: calc(95.2%/8);
    margin-right: 0.6%;

    &:nth-child(8n) {
      margin-right: 0;
    }
  }
}
</style>
