var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["betting-wrap clearfix", _vm.theme] }, [
    _c("button", {
      ref: "simulateClickElement",
      staticStyle: { display: "none" },
    }),
    _vm.isBetslipLocked
      ? _c("div", { staticClass: "locked" }, [
          _c("i", { staticClass: "icon icon-lock" }),
        ])
      : _vm._e(),
    _c("div", { staticClass: "betting-wrap-desktop" }, [
      _c("div", { staticClass: "content-spacer" }, [
        _c(
          "div",
          { staticClass: "betting-market-left" },
          _vm._l(_vm.bettingLeftGroup, function (market, i) {
            return _c("div", { key: i, staticClass: "betting-market-wrap" }, [
              market.active
                ? _c(
                    "div",
                    [
                      _c("BettingMarket", {
                        attrs: {
                          title: market.key,
                          bets: _vm.odds[market.id],
                          odds: market.odds,
                          select: _vm.toggleBet,
                          disable:
                            _vm.disabled_betting || _vm.allBettingDisabled,
                          theme: _vm.theme,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "betting-market-right" },
          _vm._l(_vm.bettingRightGroup, function (market, i) {
            return _c("div", { key: i, staticClass: "betting-market-wrap" }, [
              market.active
                ? _c(
                    "div",
                    [
                      _c("BettingMarket", {
                        attrs: {
                          title: market.key,
                          bets: _vm.odds[market.id],
                          outcomes: market.outcomes,
                          odds: market.odds,
                          select: _vm.toggleBet,
                          disable:
                            _vm.disabled_betting || _vm.allBettingDisabled,
                          theme: _vm.theme,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }