<template>
  <div class="layout-wrapper">
    <TerminalTicketHistory
      v-if="ticketHistoryTerminal.length"
      :headers="listHeaders"
      :items="listItems"
      :showArrows="arrowVisible" />
    <TerminalTicketHistoryEmpty v-else/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, parseISO } from 'date-fns';
import { find } from 'lodash';
import {
  eventBus,
  TerminalTicketHistory,
  TerminalTicketHistoryEmpty,
} from '@nsftx/games-sdk-js';

export default {
  name: 'TerminalHistory',
  components: {
    TerminalTicketHistory,
    TerminalTicketHistoryEmpty,
  },
  data() {
    return {
      arrowVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      'ticketHistoryTerminal',
      'translations',
    ]),
    listHeaders() {
      return [
        {
          text: `${this.translations.general_ticket} ID`,
          value: 'ticketId',
        },
        {
          text: this.translations.general_date,
          value: 'ticketDateTime',
        },
        {
          text: this.translations.general_pay_in,
          value: 'payin',
        },
        {
          text: this.translations.general_status,
          value: 'status',
        },
      ];
    },
    listItems() {
      return this.ticketHistoryTerminal.map((ticket, index) => ({
        id: index,
        name: 'ticket-list',
        ticketId: this.getTicketId(ticket),
        ticketDateTime: this.getDateTime(ticket),
        payin: this.getPayin(ticket),
        status: this.getStatus(ticket),
      }));
    },
  },
  methods: {
    getTicketId(ticket) {
      return ticket.id;
    },
    getDateTime(ticket) {
      return ticket.createdAt ? format(parseISO(ticket.createdAt), 'dd.MM.yyyy HH:mm:ss') : '';
    },
    getPayin(ticket) {
      return ticket.payin.toFixed(2);
    },
    getStatus(ticket) {
      return ticket.status.value;
    },
  },
  mounted() {
    eventBus.$on('printCopy', (ticketId) => {
      const ticket = find(this.ticketHistoryTerminal, { id: ticketId });
      this.$root.$emit('PrintTicketCopy', ticket);
    });
  },
};

</script>

<style scoped lang="scss">
@import '../../node_modules/@nsftx/nfont/css/icons.css';
.layout-wrapper {
  height: 100vh;
  background-color: var(--background-3);
}
</style>
