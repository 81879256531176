var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["dropdown", _vm.theme] }, [
    _c(
      "div",
      {
        staticClass: "content",
        class: { terminal: _vm.isTerminal },
        on: {
          click: function ($event) {
            return _vm.toggle()
          },
        },
      },
      [
        _vm.label
          ? _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm._f("translate")(_vm.label))),
            ])
          : _vm._e(),
        _vm.translate
          ? _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm._f("translate")(_vm.selected[_vm.value]))),
            ])
          : _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.selected[_vm.value])),
            ]),
        !_vm.isActive
          ? _c("span", { staticClass: "icon n-i n-i-arrow-down-a" })
          : _vm._e(),
        _vm.isActive
          ? _c("span", { staticClass: "icon n-i n-i-arrow-up-a" })
          : _vm._e(),
      ]
    ),
    _vm.isActive
      ? _c(
          "div",
          { staticClass: "list" },
          _vm._l(_vm.itemsFiltered, function (item, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "item-content",
                on: {
                  click: function ($event) {
                    return _vm.select(item)
                  },
                },
              },
              [
                _vm.label
                  ? _c("div", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm._f("translate")(_vm.label))),
                    ])
                  : _vm._e(),
                _vm.translate
                  ? _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm._f("translate")(item[_vm.value]))),
                    ])
                  : _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(item[_vm.value])),
                    ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }