import axios from 'axios';
import ngsRoute from '../utility/getDc';

export default {
  get: (company, channel, deliveryPlatform, lang) => {
    const api = ngsRoute.getDc();

    return axios.get(`${api}/${process.env.VUE_APP_NGS_CONFIG_PATH}/${channel}.json?channel=${deliveryPlatform}`, {
      headers: {
        'X-Nsft-Ngs-Company': company,
        'X-Nsft-Ngs-Product': channel,
        'Accept-Language': lang,
      },
    });
  },
};
