var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "tabs-wrap",
        class: { mobile: _vm.mobile, light: _vm.isLightTheme },
      },
      _vm._l(_vm.tabs, function (item, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "tabs-tab",
            class: { active: _vm.active == item },
            on: {
              click: function ($event) {
                return _vm.switchTab(item)
              },
            },
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm._f("translate")(item.key)) + "\n    "
            ),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "tabs-content" },
      _vm._l(_vm.tabs, function (item, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "slot-wrap",
            class: { active: _vm.active == item },
          },
          [_c("keep-alive", [_vm._t(item.name)], 2)],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }