import store from '@/store';

const dc = {
  getDc() {
    const dcParam = store.state.config.platformDataCenter;
    const getApi = (dcServer) => {
      const dcs = {
        default: `${process.env.VUE_APP_NGS_POINT}`,
        rs: `${process.env.VUE_APP_NGS_POINT_RS}`,
        rs2: `${process.env.VUE_APP_NGS_POINT_RS2}`,
        ro: `${process.env.VUE_APP_NGS_POINT_RO}`,
        ro2: `${process.env.VUE_APP_NGS_POINT_RO2}`,
        de: `${process.env.VUE_APP_NGS_POINT_DE}`,
      };
      if (dcServer) return dcs[`${dcServer}`];
      return dcs.default;
    };
    return getApi(dcParam);
  },
};

export default dc;
