var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { class: ["results", _vm.theme, { terminal: _vm.isTerminal }] },
        [
          _c(
            "div",
            { staticClass: "wrap-top" },
            [
              _c("Dropdown", {
                attrs: {
                  items: _vm.results,
                  label: "general_round",
                  value: "eventId",
                  translate: false,
                  limit: _vm.resultsCount,
                },
                on: { dropdownSelect: _vm.selectRound },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "wrap-left" },
            _vm._l(_vm.resultsLeftGroup, function (item, i) {
              return _c(
                "div",
                { key: i },
                [
                  item.active
                    ? [
                        _c("ResultsGroup", {
                          attrs: {
                            title: item.key,
                            odds: item.odds,
                            fields: item.value || item.outcomes,
                            prefix: item.prefix || false,
                            type: item.type,
                            result: _vm.activeRound.outcomes[i].value,
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "wrap-right" },
            _vm._l(_vm.resultsRightGroup, function (item, i) {
              return _c(
                "div",
                { key: i },
                [
                  item.active
                    ? [
                        _c("ResultsGroup", {
                          attrs: {
                            title: item.key,
                            odds: item.odds,
                            fields: item.value || item.outcomes,
                            prefix: item.prefix || false,
                            type: item.type,
                            result: _vm.activeRound.outcomes[i].value,
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }