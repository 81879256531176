<template>
  <div class="terminal-results-wrapper" v-if="loaded">
    <Results
      v-bind="$attrs"
      :config_bets="product_config.bets"
      :signal="round_phase == 'results'"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Results from '@/components/results/Results';

export default {
  name: 'TerminalReults',
  components: {
    Results,
  },
  computed: {
    ...mapGetters([
      'loaded',
      'product_config',
      'round_phase',
    ]),
  },
};

</script>

<style scoped lang="scss">
.terminal-results-wrapper {
  text-align: center;
  overflow: hidden;
  padding-top: 10px;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  ::v-deep .results {
    .wrap-top {
      height: 72px;
      margin-bottom: 10px;
      .dropdown {
        text-align: left;
        height: 72px;
        font-size: 24px;
        .list {
          margin-top: 2px;
          height: 450px;
          overflow-y: scroll;
          padding-bottom: 20px;
        }
      }
    }
  }

}
@media (min-width: 1281px) and (max-width: 1600px) {
  ::v-deep .results {
    .wrap-top {
      height: 72px;
      margin-bottom: 10px;
      .dropdown {
        text-align: left;
        height: 72px;
        font-size: 24px;
        .list {
          margin-top: 2px;
          height: 450px;
          overflow-y: scroll;
          padding-bottom: 20px;
        }
      }
    }
    .wrap-left {
      display: inline-block;
      width: 45%;
      padding-right: 20px;
      .results-group {
        .title {
          font-size: 20px;
        }
        .content {
          .results-group-field.size-8 {
            height: 72px;
            font-size: 24px;
          }
          .results-group-field.size-3 {
            height: 72px;
            font-size: 24px;
          }
        }
      }
    }
    .wrap-right {
      width: 45%;
      display: inline-block;
      .results-group {
        .title {
          font-size: 20px;
        }
        .content {
          .results-group-field.size-2 {
            height: 72px;
            font-size: 24px;
          }
          .results-group-field.size-3 {
            height: 72px;
            font-size: 24px;
          }
        }
      }
    }
  }
}
@media (min-width: 1601px) and (max-width: 1920px) {
  ::v-deep .results {
    .wrap-top {
      height: 72px;
      margin-bottom: 10px;
      .dropdown {
        text-align: left;
        height: 72px;
        font-size: 24px;
        .list {
          margin-top: 2px;
          height: 650px;
          overflow-y: scroll;
          padding-bottom: 90px;
        }
      }
    }
    .wrap-left {
      display: inline-block;
      width: 45%;
      padding-right: 20px;
      .results-group {
        .title {
          font-size: 20px;
        }
        .content {
          .results-group-field.size-8 {
            height: 72px;
            font-size: 24px;
          }
          .results-group-field.size-3 {
            height: 72px;
            font-size: 24px;
          }
        }
      }
    }
    .wrap-right {
      width: 45%;
      display: inline-block;
      .results-group {
        .title {
          font-size: 20px;
        }
        .content {
          .results-group-field.size-2 {
            height: 72px;
            font-size: 24px;
          }
          .results-group-field.size-3 {
            height: 72px;
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
