<template>
  <div>
    <div class="tabs-wrap" :class="{ mobile: mobile, light: isLightTheme }">
      <div class="tabs-tab"
        v-for="(item,i) in tabs"
        :key="i"
        @click="switchTab(item)"
        :class="{ active: active == item }">
          {{ item.key | translate }}
      </div>
    </div>
    <div class="tabs-content">
    <div v-for="(item, i) in tabs" :key="i" class="slot-wrap" :class="{ active: active == item}" >
      <keep-alive>
        <slot :name="item.name" >
        </slot>
      </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Tabs',
  props: {
    tabs: Array,
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    ...mapGetters([
      'config',
    ]),
    isLightTheme() {
      return this.config.ui.config.theme === 'light';
    },
  },
  created() {
    [this.active] = this.tabs;
  },
  methods: {
    switchTab(tab) {
      this.active = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
  .tabs-wrap {
    width: 100%;
    height: 40px;
    background: var(--card);
    border-bottom: 2px solid #353739;
    &.light {
      border-bottom: 2px solid rgba(255, 255, 255, 1);
    }
  }
  .slot-wrap {
    display: none;
    &.active {
      display: block;
    }
  }
  .tabs-tab {
    display: inline-block;
    height: 40px;
    width: 50%;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    text-transform: capitalize;
    //  transition: all .5s ease;
    &:hover {
      background: var(--card-hover);
    }
    &.active {
      border-bottom: 2px solid var(--secondary);
      font-weight: bold;
      color: var(--secondary);
    }
  }
</style>
