<template>
  <div class="betslip-area" :class="{ tablet: isTablet }">
    <component :is="betslipComponent" :bgImage="showBetslipBgImage"></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GamesBetslipA, GamesBetslipB } from '@nsftx/games-sdk-js';
import isMobile from 'ismobilejs';

export default {
  name: 'BetslipArea',
  components: {
    GamesBetslipA,
    GamesBetslipB,
  },
  computed: {
    ...mapGetters([
      'config',
      'appLayout',
    ]),
    betslipComponent() {
      if (this.appLayout === 'Compact') {
        return 'GamesBetslipB';
      }
      return this.isMob() ? 'GamesBetslipB' : 'GamesBetslipA';
    },
    showBetslipBgImage() {
      if (this.config.ui.config.theme === 'light') {
        return '../assets/empty-betslip-light.png';
      }
      return '../assets/empty-betslip.svg';
    },
    isTablet() {
      return isMobile().tablet;
    },
  },
  methods: {
    isMob() {
      return isMobile().any || window.innerWidth <= 960;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .header {
  .remove {
    z-index: 100;
  }
}
::v-deep .overlay {
  left: 0px;
}
::v-deep .betslip-bets {
  overflow-y: scroll;
  scrollbar-width: none;
}
::v-deep .betslipB {
  .overlay-wrapper {
   .betslipBFooter {
    .betslipBFooterStake {
     .betslip-input-wrapper {
      input {
        font-size: 16px;
      }
      //  input:focus {
      //    transform: scale(1);
      //  }
     }
    }
   }
  }
}
.betslip-area {
  ::v-deep .betslip {
    div {
      div {
        .betslipFooter {
          .betslipFooterLower {
            .possibleWinWrapper {
              div {
                div {
                  .payOut {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.betslip-area {
  &.tablet {
    ::v-deep .ticket-details-modal {
      .modal {
        height: unset;
      }
    }
    ::v-deep .ticket-details-table {
      .ticket-details-body-desktop {
        div {
          div {
            &:first-child {
              flex: 0.7;
            }
          }
          .rows {
            .row {
              div {
                &:first-child {
                  flex: 0.7;
                }
              }
            }
          }
        }
      }
    }
  }
}
.betslip-area {
  ::v-deep .betslipB {
    .betslipA {
      div {
        .betslip {
          div {
            div {
              .betslipFooter {
                .betslipFooterUpper {
                  .betslipUpperWrapperB {
                    .possibleWinWrapper {
                      div {
                        .payOut {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
