var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { class: ["betting-market-label", _vm.theme] }, [
      _vm._v(
        _vm._s(_vm._f("translate")(_vm.title)) + " " + _vm._s(_vm.parsedOdd)
      ),
    ]),
    _c(
      "div",
      { staticClass: "betting-market-bets" },
      _vm._l(_vm.bets, function (bet, i) {
        return _c("Button", {
          key: i,
          staticClass: "betting-button",
          class: [_vm.buttonType(bet)],
          attrs: {
            label: bet.outcome,
            disabled: _vm.disable,
            variation: _vm.isActive(bet) ? "brand" : "default",
          },
          on: {
            change: function ($event) {
              return _vm.selectBet(bet)
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }