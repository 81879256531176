<template>
  <div :class="['results', theme, {terminal: isTerminal}]" v-if="loaded">
    <div class="wrap-top">
      <Dropdown :items="results"
                :label="'general_round'"
                :value="'eventId'"
                :translate="false"
                :limit="resultsCount"
                @dropdownSelect="selectRound"/>
    </div>

    <div class="wrap-left">
      <div v-for="(item, i) in resultsLeftGroup"
           :key="i">
        <template v-if="item.active">
          <ResultsGroup :title="item.key"
                        :odds="item.odds"
                        :fields="item.value || item.outcomes"
                        :prefix="item.prefix || false"
                        :type="item.type"
                        :result="activeRound.outcomes[i].value" />
        </template>
      </div>
    </div>

    <div class="wrap-right">
      <div v-for="(item, i) in resultsRightGroup"
           :key="i">
        <template v-if="item.active">
          <ResultsGroup :title="item.key"
                        :odds="item.odds"
                        :fields="item.value || item.outcomes"
                        :prefix="item.prefix || false"
                        :type="item.type"
                        :result="activeRound.outcomes[i].value" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import ResultsApi from '@/api/results';
import Dropdown from '@/components/common/Dropdown';
import ResultsGroup from '@/components/results/ResultsGroup';

export default {
  name: 'Results',

  components: {
    ResultsGroup,
    Dropdown,
  },

  props: {
    config_bets: {
      type: Object,
    },
    signal: {
      type: [Number, Boolean],
    },
  },

  data() {
    return {
      results: [],
      resultsCount: 10,
      loaded: false,
      activeRound: null,
      markets: {
        field: {
          key: 'betting.field_betting',
          value: 24,
          type: 'size-8',
        },
        column: {
          key: 'betting.column_betting',
          value: 8,
          prefix: 'general.column',
          type: 'size-8',
        },
        row: {
          key: 'betting.row_betting',
          value: 3,
          prefix: 'general.row',
          type: 'size-3',
        },
        odd_even: {
          key: 'betting.odd_or_even',
          outcomes: [
            {
              value: 'odd',
              key: 'general.odd',
            },
            {
              value: 'even',
              key: 'general.even',
            },
          ],
          type: 'size-2',
        },
        color: {
          key: 'betting.purple_or_yellow',
          outcomes: [
            {
              value: 'purple',
              key: 'general.purple',
            },
            {
              value: 'yellow',
              key: 'general.yellow',
            },
          ],
          type: 'size-2',
        },
        side: {
          key: 'betting.left_or_right',
          outcomes: [
            {
              value: 'left',
              key: 'general.left',
            },
            {
              value: 'right',
              key: 'general.right',
            },
          ],
          type: 'size-2',
        },
        no_goal: {
          key: 'betting.no_goal',
          outcomes: [
            {
              value: 'miss',
              key: 'general.miss',
            },
            {
              value: 'save',
              key: 'general.save',
            },
            {
              value: 'post',
              key: 'general.post',
            },
          ],
          type: 'size-3',
        },
      },
    };
  },

  created() {
    this.results = [this.activeRound];
    this.fetchResults();
    this.setBetsConfig();
  },
  methods: {
    setBetsConfig() {
      _.forEach(this.product_config.bets, (bet) => {
        const market = this.markets[bet.keyname];
        market.id = bet.id;
        market.active = bet.active;
        market.odds = bet.odds;
      });
    },
    selectRound(item) {
      this.activeRound = this.results[this.results.indexOf(item)];
    },
    fetchResults() {
      ResultsApi.get(this.config.company, this.config.channel).then((res) => {
        this.parseData(res.data.events);
      });
    },

    parseData(data) {
      const parsed = [];
      let i = 0;
      for (i = 0; i < data.length; i += 1) {
        parsed.push(this.parseEvent(data[i]));
      }

      this.results = parsed;
      [this.activeRound] = this.results;
      this.loaded = true;
    },

    parseEvent(data) {
      const mapFields = {
        1: {
          source: 'field',
          to: 'field',
        },
        2: {
          source: 'fieldOddEven',
          to: 'odd_even',
        },
        3: {
          source: 'fieldColor',
          to: 'color',
        },
        4: {
          source: 'goalRow',
          to: 'row',
        },
        5: {
          source: 'goalColumn',
          to: 'column',
        },
        6: {
          source: 'goalSide',
          to: 'side',
        },
        7: {
          source: 'noGoal',
          to: 'no_goal',
        },
      };
      const obj = {
        eventId: data.eventIdToday,
        time: data.eventTime,
        outcomes: {},
      };

      for (let i = 1; i <= Object.keys(this.config_bets).length; i += 1) {
        const d = data.outcomes[mapFields[this.config_bets[i].id].source];

        obj.outcomes[mapFields[this.config_bets[i].id].to] = {
          name: mapFields[this.config_bets[i].id].to,
          value: d ? d.outcome : false,
        };
      }

      return obj;
    },
  },

  computed: {
    ...mapGetters([
      'config',
      'product_config',
      'theme',
      'isTerminal',
    ]),
    resultsLimited() {
      return this.results.slice(0, this.resultsCount);
    },
    resultsLeftGroup() {
      return {
        field: this.markets.field,
        column: this.markets.column,
        row: this.markets.row,
      };
    },
    resultsRightGroup() {
      return {
        odd_even: this.markets.odd_even,
        color: this.markets.color,
        side: this.markets.side,
        no_goal: this.markets.no_goal,
      };
    },
  },
  watch: {
    signal(value) {
      if (value) {
        setTimeout(() => {
          this.fetchResults();
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">

.results {
  background: var(--card);
  padding: 10px 10px;
  color: var(--text-primary-2);
  padding-bottom: 30px;
  width: 100%;

  .wrap-top {
    color: var(--text-primary-1);
    width: 100%;
    padding-top: 20px;
  }

  .wrap-left {
    width: 100%;
  }

  .wrap-right {
    width: 100%;
  }

  @media (min-width: 660px) and (max-width: 1268px){
    .results {
      .wrap-left {
        display: inline-block;
        vertical-align: top;
        width: 55%;
        margin-right: 5%;
      }
      .wrap-right {
        display: inline-block;
        vertical-align: top;
        width: 40%;
      }
      .wrap-top {
        width: 50%;
      }
    }
    .results.terminal {
      .wrap-top {
        width: 100%;
      }
    }
  }
}
</style>
