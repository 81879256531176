var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "boost-manager" }, [
    _vm.isMobileDeviceDevicePhone
      ? _c("div", {
          class: {
            "mobile-ios":
              !_vm.isIntegration &&
              _vm.config.applicationName === "Mobile" &&
              _vm.isMobileDeviceIos,
          },
          attrs: { id: "boostBadgeContainer" },
        })
      : _vm._e(),
    _c("div", {
      class: { isTablet: _vm.isTablet },
      attrs: { id: "boostContainer" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }