<template>
  <div :class="['betting-wrap clearfix', theme]">
    <button ref="simulateClickElement" style="display: none;"></button>
    <div class="locked" v-if="isBetslipLocked">
      <i class="icon icon-lock" />
    </div>
    <div class="betting-wrap-desktop">
      <div class="content-spacer">
        <!-- LEFT -->
        <div class="betting-market-left">
            <div class="betting-market-wrap" v-for="(market, i) in bettingLeftGroup"
                                            :key="i">

                <div v-if="market.active">
                  <BettingMarket :title="market.key"
                                :bets="odds[market.id]"
                                :odds="market.odds"
                                :select="toggleBet"
                                :disable="disabled_betting || allBettingDisabled"
                                :theme="theme">
                  </BettingMarket>
                </div>

            </div>
        </div>
        <!-- RIGHT -->
        <div class="betting-market-right">
          <div class="betting-market-wrap" v-for="(market, i) in bettingRightGroup"
                                           :key="i">
              <div v-if="market.active">
                <BettingMarket :title="market.key"
                              :bets="odds[market.id]"
                              :outcomes="market.outcomes"
                              :odds="market.odds"
                              :select="toggleBet"
                              :disable="disabled_betting || allBettingDisabled"
                              :theme="theme">
                </BettingMarket>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forEach, find } from 'lodash';
//  import { eventBus } from '@nsftx/games-sdk-js';
import { mapGetters, mapActions } from 'vuex';
import BettingMarket from '@/components/betting/BettingMarketTerminal';

export default {
  name: 'Betting',
  components: {
    BettingMarket,
  },
  props: {
    send: {
      type: Function,
    },
    remove: {
      type: Function,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      markets: {
        field: {
          key: 'betting.field_betting',
          bets: [],
        },
        column: {
          key: 'betting.column_betting',
          bets: [],
        },
        row: {
          key: 'betting.row_betting',
          bets: [],
        },
        odd_even: {
          key: 'betting.odd_or_even',
          bets: [],
          outcomes: [
            {
              value: 'odd',
              key: 'general.odd',
            },
            {
              value: 'even',
              key: 'general.even',
            },
          ],
        },
        color: {
          key: 'betting.purple_or_yellow',
          bets: [],
          outcomes: [
            {
              value: 'purple',
              key: 'general.purple',
            },
            {
              value: 'yellow',
              key: 'general.yellow',
            },
          ],
        },
        side: {
          key: 'betting.left_or_right',
          bets: [],
          outcomes: [
            {
              value: 'left',
              key: 'general.left',
            },
            {
              value: 'right',
              key: 'general.right',
            },
          ],
        },
        no_goal: {
          key: 'betting.no_goal',
          bets: [],
          outcomes: [
            {
              value: 'miss',
              key: 'general.miss',
            },
            {
              value: 'save',
              key: 'general.save',
            },
            {
              value: 'post',
              key: 'general.post',
            },
          ],
        },
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions([
      'activate_bet',
      'deactivate_bet',
      'activate_notification',
      'gamesBetslip/addBet',
      'gamesBetslip/removeBet',
      'gamesBetslip/setIsPayinInProgress',
    ]),
    init() {
      forEach(this.product_config.bets, (bet) => {
        const market = this.markets[bet.keyname];
        market.id = bet.id;
        market.active = bet.active;
        market.odds = bet.odds;
      });
    },
    simulateClick() {
      const simulateElement = this.$refs.simulateClickElement;
      simulateElement.click();
      //  eventBus.$emit('BetRemoved');
    },
    toggleBet(bet) {
      const selectedBet = find(this['gamesBetslip/ticket'], betOnTicket => betOnTicket.id === bet.id);
      if (selectedBet) {
        this.removeBet(selectedBet);
        selectedBet.active = false;
      } else {
        this.addBet(bet);
      }
    },
    addBet(bet) {
      this.simulateClick();
      const outcome = String(bet.outcome);
      const odd = `${Number(bet.odd)}.00`;
      const { market } = bet;
      // eslint-disable-next-line
      bet.outcome = outcome;
      // eslint-disable-next-line
      bet.odds = Number(odd);
      // eslint-disable-next-line
      bet.market = market;
      // eslint-disable-next-line
      bet.roundNumber = bet.round;
      // reset bet stake
      // eslint-disable-next-line
      bet.stake = 0;
      this['gamesBetslip/addBet'](bet);
      this['gamesBetslip/setIsPayinInProgress'](false);
      this.activate_bet(bet);
    },
    removeBet(bet) {
      this['gamesBetslip/removeBet'](bet.id);
      this.deactivate_bet(bet);
      this.simulateClick();
    },
    validateBetNumberLimit() {
      const keys = Object.keys(this.active_bets).length;
      return keys < this.product_config.rules.maxBetNumber.value;
    },
  },

  computed: {
    ...mapGetters([
      'odds',
      'product_config',
      'round',
      'disabled_betting',
      'active_bets',
      'theme',
      'gamesBetslip/ticket',
      'allBettingDisabled',
    ]),
    ...mapGetters({
      isBetslipLocked: 'betslip/isBetslipLocked',
    }),
    bettingLeftGroup() {
      return {
        field: this.markets.field,
        column: this.markets.column,
        row: this.markets.row,
      };
    },
    bettingRightGroup() {
      return {
        odd_even: this.markets.odd_even,
        color: this.markets.color,
        side: this.markets.side,
        no_goal: this.markets.no_goal,
      };
    },
    mobileSpecialGroup() {
      return {
        column: this.markets.column,
        row: this.markets.row,
        odd_even: this.markets.odd_even,
        color: this.markets.color,
        side: this.markets.side,
        no_goal: this.markets.no_goal,
      };
    },
  },
};
</script>

<style scoped lang="scss">

.betting-wrap {
  position: relative;
  background: var(--card);
  clear: both;
  .locked {
    position: absolute;
    background-color: var(--overlay-lock);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      width: 30px;
      height: 30px;
    }
  }
}

.clearfix::before,
.clearfix::after{
  content: " ";
  display: table;
}

.betting-content-mobile {
  padding: 20px 10px;

  > .normal-betting {
    width: 100%;
    margin: 0 auto;
  }

  > .special-betting {
    width: 100%;
    margin: 0 auto;
  }
}

.content-spacer {
  padding: 16px 8px;
}

.betting-market-left {
  float: left;
  width: 720px;
  font-size: 20px;
}

.betting-market-right {
  padding-left: 720px;
  font-size: 20px;
}

.betting-market-wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;

  > div {
    margin-bottom: 40px;
  }
}
.betting-market-disabled-overlay {
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.3);
}
@media (max-width: 1024px) {
  .betting-market-wrap {
    > div {
      margin-bottom: 27px;
    }
  }
  .betting-market-left {
    float: left;
    width: 500px;
    font-size: 16px;
  }

  .betting-market-right {
    padding-left: 500px;
    font-size: 16px;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .betting-market-wrap {
    > div {
      margin-bottom: 27px;
    }
  }
  .betting-market-left {
    float: left;
    width: 500px;
    font-size: 16px;
  }

  .betting-market-right {
    padding-left: 500px;
    font-size: 16px;
  }
}
@media (min-width: 1367px) and (max-width: 1680px) {
  .betting-market-wrap {
    > div {
      margin-bottom: 27px;
    }
  }
  .betting-market-left {
    float: left;
    width: 555px;
    font-size: 16px;
  }

  .betting-market-right {
    padding-left: 585px;
    font-size: 16px;
  }
}
</style>
