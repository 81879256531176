var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "statistics-group-field" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v("\n    " + _vm._s(_vm._f("translate")(_vm.title)) + "\n  "),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      _vm._l(_vm.stats, function (item, i) {
        return _c("div", { key: i, staticClass: "field-wrap" }, [
          _c("div", { staticClass: "field" }, [
            _c("span", { staticClass: "value" }, [_vm._v(_vm._s(item.label))]),
          ]),
          _c("div", { staticClass: "label" }, [
            _c("span", [_vm._v(_vm._s(item.value))]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }