<template>
  <div :class="['dropdown', theme]">
    <div class="content" :class="{terminal: isTerminal}" @click="toggle()">
      <div class="label" v-if="label">{{ label | translate}}</div>
      <div class="value" v-if="translate">{{ selected[value] | translate }}</div>
      <div class="value" v-else> {{ selected[value] }}</div>
      <span class="icon n-i n-i-arrow-down-a" v-if="!isActive"></span>
      <span class="icon n-i n-i-arrow-up-a" v-if="isActive"></span>
    </div>

    <div class="list" v-if="isActive">
      <div class="item-content" v-for="(item, i) in itemsFiltered" :key="i"
                              @click="select(item)">
        <div class="label" v-if="label">{{ label | translate }}</div>
        <div class="value" v-if="translate">{{ item[value] | translate }}</div>
        <div class="value" v-else>{{ item[value] }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Dropdown',

  props: {
    items: {
      type: [Array, Object],
    },
    label: {
      type: [String, Boolean],
      default: false,
    },
    value: {
      type: String,
    },
    limit: {
      type: Number,
      default: 10,
    },
    translate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
      selected: null,
    };
  },
  created() {
    [this.selected] = this.items;
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
    select(item) {
      this.toggle();
      this.selected = item;
      this.$emit('dropdownSelect', this.selected);
    },
  },

  computed: {
    ...mapGetters([
      'theme',
      'isTerminal',
    ]),
    itemsFiltered() {
      return this.items ? this.items.slice(0, this.limit) : '';
    },
  },
  watch: {
    items() {
      [this.selected] = this.items;
    },
  },
};
</script>

<style scoped lang="scss">

.dropdown {
  position: relative;
  height: 40px;
  background: var(--input);
  border-radius: 3px;
  line-height: 40px;
  cursor: pointer;

  .content {
    padding-left: 15px;
    line-height: 40px;
    &.terminal {
      margin-bottom: 1px;
    }

    .label,
    .value {
      display: inline-block;
    }

    .label {
      padding-right: 5px;
    }
  }

  .icon {
    float: right;
    text-align: center;
    vertical-align: middle;
    padding-right: 25px;
    line-height: 3;
  }

  .list {
    background: var(--input);

    .item-content {
      padding-left: 15px;

      .label {
        padding-right: 5px;
      }
      .label,
      .value {
        display: inline-block;
      }
      &:hover {
        background: var(--button-hover);
      }
    }
  }
}
</style>
