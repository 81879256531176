<template>
  <div :class="['statistics-group-progressbar', theme, {terminal: isTerminal}]">
    <div class="fill"
         :class="{[parseClass + num]:true}"
         :style="{ width: value + '%' }">
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StatisticsGroupProgressBar',

  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    type: {
      type: String,
      default: '',
    },
    num: {
      type: [String, Number],
    },
  },

  computed: {
    ...mapGetters([
      'theme',
      'isTerminal',
    ]),
    parseClass() {
      return `${this.type.toLowerCase()}-`;
    },
  },
};
</script>

<style scoped lang="scss">
.statistics-group-progressbar {
  height: 15px;
  border-radius: 2px;
  background-color: var(--background-1);
  margin-bottom: 10px;

  .fill {
    height: 100%;
    border-radius: 2px;
    background: var(--primary);
    transition: width 0.4s ease-in;
  }

  $column-stats-colors: #c1024f, #00a3a3, #fe7d22, #21adff, #4617b4, #189900, #ff1e77, #632f00;
  $row-stats-colors: #fe7d22, #21adff, #ff1e77;
  $even-odd-stats-colors: #c1024f, #21adff;
  $color-stats-colors: #6b2ad5, #f3b300;
  $side-stats-colors: #00a3a3, #6b2ad5;
  $no-goal-stats-colors: #21adff, #6b2ad5, #ff1e77;

  @for $i from 1 through length($column-stats-colors) {
    $background: nth($column-stats-colors, $i);
    $index: $i - 1;

    .column-#{$index} {
        background: $background;
    }
  }

  @for $i from 1 through length($row-stats-colors) {
    $background: nth($row-stats-colors, $i);
    $index: $i - 1;

    .row-#{$index} {
        background: $background;
    }
  }

  @for $i from 1 through length($even-odd-stats-colors) {
    $background: nth($even-odd-stats-colors, $i);
    $index: $i - 1;

    .odd_even-#{$index} {
        background: $background;
    }

  }

  @for $i from 1 through length($color-stats-colors) {
    $background: nth($color-stats-colors, $i);
    $index: $i - 1;

    .color-#{$index} {
        background: $background;
    }
  }

  @for $i from 1 through length($side-stats-colors) {
    $background: nth($side-stats-colors, $i);
    $index: $i - 1;

    .side-#{$index} {
        background: $background;
    }
  }

  @for $i from 1 through length($no-goal-stats-colors) {
    $background: nth($no-goal-stats-colors, $i);
    $index: $i - 1;

    .no_goal-#{$index} {
        background: $background;
    }
  }

}
@media (min-width: 1367px) and (max-width: 1680px) {
  .statistics-group-progressbar.terminal {
    height: 16px;
    margin-bottom: 9px;
  }
}
@media (min-width: 1910px) and (max-width: 1925px) {
  .statistics-group-progressbar.terminal {
    height: 20px;
    margin-bottom: 4.5px;
  }
}
</style>
